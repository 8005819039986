/*------------------------------------*
	@COMPONENTS #CHECKBOX
\*------------------------------------*/

.checkbox {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 40px;

    input {
        @include hide-hide;
    }

    @media (max-width: 620px) {
        margin-bottom: 20px;
    }
}

    .checkbox__label {
        color: $grey-dark;
        font-size: 12px;
        font-weight: 400;
        position: relative;
        padding-left: 30px;
        margin-bottom: 0;
        @include trans;

        input:checked + & {
            color: $black;
        }
        
        &::before,
        &::after {
            content: '';
            position: absolute;
            @include trans;
        }

        &::before {
            width: 18px;
            height: 18px;
            border-radius: 2px;
            border: 1px solid #979797;
            background-color: $white;
            top: 0;
            left: 0;
        }

        &::after {
            content:'';
            background-image: url(../images/icon-check.svg);
            background-repeat: no-repeat;
            background-size: cover;
            background-position: 50% 0;
            width: 12px;
            height: 9px;
            top: 5px;
            left: 3px;
            opacity: 0;
        }

        input:checked + & {
            &::before {
                background-color: $white;
                border: 1px solid $green;
            }

            &::after {
                opacity: 1;
            }
        }

        @media (max-width: 620px) {
            font-size: 10px;
        }
    }