/*------------------------------------*
    @OBJECTS #TABLES
\*------------------------------------*/

table,
.table {
    width: 100%;

    th {
    	font-weight: normal;
    }
}
