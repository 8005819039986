/*------------------------------------*
	@COMPONENTS #LINK
\*------------------------------------*/

.link {
	color: $grey-dark !important;
	font-size: 14px;
	font-weight: 400;
	text-decoration: none !important;
	display: flex;
	align-items: center;
	
	&:hover {
		opacity: 0.5;
	}

	small {
		font-size: 12px;
	}

	.icon {
		width: 6px;
		height: 10px;
		color: $grey-dark;
		margin-left: 10px;
	}

	@media (max-width: $screen-sm) {
		font-size: 12px;
	}
}

.link--green {
    color: $green !important;
    font-size: 12px;
	font-weight: 400;

	@media (max-width: 620px) {
		font-size: 10px;
	}
}

.link--grey {
	color: $grey-dark;
	font-size: 24px;
	font-weight: 400;
	line-height: 25px;
	text-align: left;

	@media (max-width: $screen-sm) {	
		font-size: 18px;
	}
}