/*------------------------------------*
	@COMPONENTS #RECEIPT
\*------------------------------------*/

.receipt--alt {
    margin-bottom: 60px;
}

    .receipt__list {
        color: $black;
        font-size: 12px;
        display: flex;
        justify-content: flex-end;
        margin-bottom: 40px;

        dt {
            color: #797979;
            font-weight: 400;
        }

        dd {
            color: #ff6d6c;
            font-weight: 500;
            margin-left: 5px;
        }

        hr + & {
            margin-top: -35px;
        }

        & + .form-group {
            margin-top: -20px;
        }

        @media (max-width: $screen-sm) {
            hr + & {
                margin-top: -25px;
            }
        }

        @media (max-width: $screen-xs) {
            margin-bottom: 30px;
        }
    }

    .receipt__list--alt {
        justify-content: flex-start;
        max-width: 268px;

        span {
            color: red;
        }
    }