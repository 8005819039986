/*------------------------------------*
	@COMPONENTS #FLAG
\*------------------------------------*/

.flag-wrap {
    display: flex;
    align-items: center;
    margin-bottom: 25px;
	position: relative;
	
    &:last-of-type {
        margin-bottom: 20px !important;
    }

    input {
        @include hide-hide;
	}

	 @media (max-width: 620px) {
		margin-bottom: 10px;
	
		&:last-of-type {
			margin-bottom: 15px !important;
		}
	}
}

	.flag {
		width: 100%;
		background-color: $white;
		box-shadow: 1px 2px 6px rgba(213, 213, 213, 0.8);
		border-radius: 10px;
		margin-bottom: 0;
		display: flex;
		justify-content: space-between;
		border: 1px solid transparent;
		transition: all .3s ease 0s;

		.icon-cart {
			width: 24px;
			height: 23px;
			color: $grey;
			position: absolute;
			top: 54px;
			left: 15px;
		}
		
		.flag-wrap input:checked + & {
			box-shadow: 1px 2px 14px #c8bfbf;
			border-radius: 10px;
			border: 1px solid #1acd8e;

			.icon-cart {
				color: $green;
			}
		}
	}

	.flag--alt {
		box-shadow: none;
		padding-top: 100px;
		margin-bottom: 90px;

		.flag__content {
			padding-left: 0;
			padding-right: 0;
			
			.dl-price--col {
				margin-bottom: 25px;
				padding-right: 0px;
				border-right: none;

				dt {
					color: $black;
					font-size: 14px;
				}

				dd {
					flex-direction: column;
				}
			}

			.flag__img {
				flex-shrink: 1;
			}
		}
		
		@media (max-width: $screen-md) {
			.flag__img {
				padding-right: 15px;
				margin-right: 15px;
			}
		}
		
		@media (max-width: $screen-sm) {
			padding-top: 40px;
			margin-bottom: 40px;

			.flag__img {
				img {
					width: 100%;
				}
			}
		}
	}

		.flag__check {
			width: 24px;
			height: 24px;
			border-radius: 100%;
			box-shadow: inset 1px 1px 2px 2px rgba(228, 228, 228, 0.5);
			background-color: $white;
			position: absolute;
			top: 15px;
			left: 15px;
			display: flex;
			justify-content: center;
			align-items: center;
			transition: all .3s ease 0s;

			.icon-check {
				width: 11px;
				height: 8px;
				color:transparent;
			}
				
			input:checked + .flag & {
				background-color: $green;
				box-shadow: none;

				.icon-check {
					color: $white;
				}
			}
		}

		.flag__img {
			border-right: 1px solid rgba(121, 121, 121, 0.15);
			padding-right: 30px;
			margin-right: 30px;
			margin-bottom: 0;

			img {
				max-width: 200px;
			}
		}

		.flag__content {
			padding: 15px 30px 15px 70px;
			flex: 1;
			display: flex;
			align-items: flex-end;
		}

			.flag__promo {
				display: flex;
				align-items: center;
				flex-wrap: wrap;
				margin: -5px;

				> * {
					margin: 5px;
				}
				
				span {
					font-size: 12px;
					font-weight: 500;
					color: $black;
					padding: 5px 15px;
					border-radius: 10px;
					background-color: $grey-light;
					white-space: nowrap;
				}

				strong {
					color: $red;
					margin-left: 5px;
				}

				small {
					color: $grey-dark;
					font-size: 12px;
					font-weight: 300;
				}
			}

		.flag__ribbon {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			text-align: center;
			top: 15px;
			right: -6px;
			position: absolute;
			width: 70px;
			height: 40px;
			background-color: $orange;
			border-radius: 10px 0 0 10px;

			p {
				color: $white;
				font-size: 10px;
				font-weight: 900;
				line-height: 10px;
				text-transform: uppercase;
				margin-bottom: 3px;
			}

			span {
				display: flex;

				.icon {
					width: 6px;
					height: 6px;
				}
			}

			&::before {
				color: $orange;
				@include triangle(right, 7px, 7px);
				position: absolute;
				top: -6px;
				right: 0px;
			}
		}