/*------------------------------------*
	@COMPONENTS #LAYOUT
\*------------------------------------*/

.list {
    padding-left: 40px;

    li {
        margin-bottom: 30px;
        position: relative;
        list-style: none;

        &::before {
            content: '';
            background-image: url(../images/icon-check-orange.svg);
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            width: 17px;
            height: 12px;
            position: absolute;
            top: 5px;
            left: -40px;

        }
    }
}

.list--bottom {
    margin-bottom: 120px;

    li {
        color: $black;
        font-size: 18px;
        margin-bottom: 20px;
    }

    @media (max-width: $screen-md) {	
        margin-bottom: 60px;
    }

    @media (max-width: $screen-xs) {	
        margin-bottom: 40px;
        
        li {
            font-size: 16px;
        }
    }
}