/*------------------------------------*
	@COMPONENTS #FOOTER
\*------------------------------------*/

.footer {
	box-shadow: -3px 1px 12px rgba(0, 0, 0, 0.07);
	padding-top: 40px;
	
	.container {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	@media (max-width: 575px) {
		padding-top: 15px;
	}
}

.footer--inner {
	background-color: #fafafa;
	box-shadow: none;
	padding-top: 90px;

	.container {
		display: flex;
		flex-direction: column;
		align-items: stretch;
	}

	.footer__logo {
		margin-bottom: 30px;

		img {
			height: 60px;
		}

		@media (max-width: $screen-sm) {	
			img {
				height: 40px;
			}
		}

		@media (max-width: 575px) {	
			text-align: center;
		}
	}

	.footer__content {
		padding-bottom: 70px;

		@media (max-width: 575px) {	
			text-align: center;
			padding-bottom: 0;

			.row {
				& > * {
					text-align: center !important;
				}
			}
		}
	}

	.footer__text {
		color: $grey-dark;
		font-size: 18px;
		font-weight: 500;
		text-transform: lowercase;
	}

	@media (max-width: $screen-sm) {	
		padding-top: 40px;
	}
}

	.footer__item {
		display: flex;
		flex-direction: column;
		align-items: flex-start;

		@media (max-width: 575px) {	
			align-items: center;
			margin-bottom: 30px;

			> * {
				display: flex;
				justify-content: center;
				align-items: center;
				width: 100%;
			}
		}
	}

	.footer__menu {
		margin-bottom: 40px;
		
		a {
			color: $grey-dark;
			font-size: 14px;
			letter-spacing: 0.03px;

			&:not(:last-child) {
				margin-right: 10px;
				position: relative;

				&::after {
					content: '';
					width: 1px;
					height: 15px;
					background-color: $grey-dark;
					position: absolute;
					top: 0;
					right: -5px;
				}
			}

			&:hover {
				text-decoration: none;
				color: $grey;
			}
		}

		@media (max-width: 575px) {
			margin-bottom: 15px;
		}
	}