/*------------------------------------*
	@COMPONENTS #INFO
\*------------------------------------*/

.info-wrap {
    box-shadow: -3px 1px 12px rgba(0, 0, 0, 0.07);
    padding-top: 40px;
    padding-bottom: 40px;
    width: 100vw;

    @media (max-width: 620px) {
        padding-top: 30px;
        padding-bottom: 30px;
        
        hr {
            margin-top: 15px;
            margin-bottom: 15px;
        }
    }
}

.info-wrap--sm {
    @media (max-width: $screen-xs) {	
        display: none;
    }
}

.info-wrap--xs {
    display: none;

    @media (max-width: $screen-xs) {	
        display: block;
        padding-top: 15px;
        padding-bottom: 15px;
    }
}

.info {
    display: flex;
    justify-content: center;
    
    .info__content {
        display: flex;

        .icon {
            margin-right: 15px;
            width: 30px;
            height: 30px;
        }

        p {
            color: $grey-dark;
            font-size: 14px;
            font-weight: 400;
            
            strong {
                display: block;
                font-weight: 400;
                color: $black;
            }
        }

        &:not(:last-child) {
            margin-right: 60px;
        }
    }

    @media (max-width: 620px) {
        .info__content {
            flex-direction: column;
            align-items: center;

            &:not(:last-child) {
                margin-right: 20px;
            }

            .icon {
                width: 25px;
                height: 25px;
                margin-right: 0;
                margin-bottom: 10px;
            }

            p {
                font-size: 12px;

                span {
                    display: none;
                }
            }
        }
    }
}

.info__box {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin: -8px;

    > * {
       margin: 8px !important;
    }
}

.info__box--alt {
    flex-wrap: nowrap;
    
    > * {
        flex-shrink: 1;
        min-width: 0;
    }

    @media (max-width: $screen-sm) {	
        margin: -4px;
        
        > * {
            margin: 4px !important;
        }
    }

    @media (max-width: $screen-xs) {	
        img {
            max-height: 12px;
        }
    }
}