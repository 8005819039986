/*------------------------------------*
	@COMPONENTS #ICONS
\*------------------------------------*/

.icon-social-message {
    width: 24px;
    height: 19px;

    @media (max-width: $screen-sm) {
        width: 18px;
        height: 14px;
    }
}

.icon-social-instagram {
    width: 20px;
    height: 20px;

    @media (max-width: $screen-sm) {
        width: 15px;
        height: 14px;
    }
}

.icon-social-facebook {
    width: 10px;
    height: 20px;

    @media (max-width: $screen-sm) {
        width: 7px;
        height: 14px;
        
    }
}

.icon-phone {
    width: 18px;
    height: 19px;
    
    @media (max-width: $screen-sm) {
        width: 12px;
        height: 14px;
    }
}

.icon-mail {
    width: 24px;
    height: 19px;
    
    @media (max-width: $screen-sm) {
        width: 18px;
        height: 14px;
    }
}


.icon-star {
    color: $orange;
}