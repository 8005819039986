/*------------------------------------*
	@COMPONENTS #PAYMENTS
\*------------------------------------*/

.payments-wrap {
    display: flex;

    @media (max-width: $screen-xs) {
        justify-content: center;
    }
}
.payments {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 40px;

    &:not(:last-child) {
        margin-right: 30px;
    }

    img {
        margin-bottom: 6px !important;
    }
}