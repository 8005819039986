/*------------------------------------*
	@COMPONENTS #FLY
\*------------------------------------*/
	
	.fly {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 15px 15px 10px;
        background-color: $white;
        box-shadow: -3px 1px 12px rgba(0, 0, 0, 0.2);
        transform: translateY(200%);
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100vw;
        z-index: 1111;
        transition: all .5s ease;

        .btn {
            width: 100%;
            max-width: 320px;

            &:not(:last-child) {
                margin-bottom: 5px;
            }
        }

        span {
            color: $green;
            font-size: 12px;
        }

        .scrolled &  {
            transform: translateY(0);
        }

        @media (min-width: $screen-xs + 1) {
            display: none;
        }
    }