/*------------------------------------*
	@COMPONENTS #QUANTITY
\*------------------------------------*/

.qty {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    max-width: 40px;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    
	.form-control {
        height: 40px;
        border-radius: 3px;
        border: 1px solid #dbdbdb;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin-top: 30px;
        margin-bottom: 30px;
        padding: 5px;

        @media (max-width: $screen-sm) {
            margin-top: 20px;
            margin-bottom: 20px;
        }
	}

	.btn {
        width: 24px;
        height: 24px;
        padding: 0 !important;
        margin-bottom: 0px;
        background-color: #c9c9c9;
        border-radius: 999px;
        text-transform: none;
        display: flex;
        justify-content: center;
        align-items: center;

        .icon {
            width: 10px;
            height: 10px;
        }

        &:hover {
            background-color: $green;
        }
    }
	
	.qty__down {
        font-size: 1.5rem;
        margin-bottom: 0;
	}
}