/*------------------------------------*
	@COMPONENTS #FORMS
\*------------------------------------*/

.form-control {
   color: $black;
   border-color: transparent;
   background-color: $white;
   border-radius: 10px;
   @include trans;
   height: 50px;

   &:focus {
      box-shadow: none;
      border-color: $grey !important;
   }

   /*PLACEHOLDER*/
   &::-webkit-input-placeholder {
      color: $grey-dark;
      font-size: 16px;
      font-weight: 400;
   }

   &::-moz-placeholder {
      color: $grey-dark;
      opacity: 1;  
      font-size: 16px;
      font-weight: 400;
   }

   &:-ms-input-placeholder {  
      color: $grey-dark;
      font-size: 16px;
      font-weight: 400;  
   }

   @media (max-width: 620px) {
      height: 44px;
      padding-left: 20px;

      /*PLACEHOLDER*/
      &::-webkit-input-placeholder {
         font-size: 14px;
      }

      &::-moz-placeholder {
         font-size: 14px;
      }

      &:-ms-input-placeholder {  
         font-size: 14px;  
      }
   }
}

   .form-group {
      margin-bottom: 15px;

      label {
         font-size: 12px;
         margin-bottom: 5px;
         line-height: 1;
      }

      @media (max-width: 620px) {
         margin-bottom: 10px;
      }
   }

   .form-group--alt {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-bottom: 20px;
      
      label {
         margin-right: 10px;
         color: $grey-dark;
         font-size: 14px;
         font-weight: 400;
      }
      
      .form-control {
         max-width: 168px;
         border-radius: 10px;
      }

      @media (max-width: $screen-md) {
         .form-control {
            max-width: 150px;
         }
      }

      @media (max-width: 620px) {
         margin-bottom: 25px;

         .form-control {
            height: 36px;
         }
      }
   }

   select.form-control {
      color: $grey-dark;
      opacity: 1;
      appearance: none;
      background-image: url(../images/icon-angle-down.svg);
      background-repeat: no-repeat;
      background-position: right 10px top 50%;
      background-size: 12px 8px;
      padding-left: 10px;
      padding-right: 30px;

      @media (max-width: 620px) {
         padding-left: 20px;
         background-position: right 15px top 50%;
      }
   }