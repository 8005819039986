/*------------------------------------*
	@COMPONENTS #FAQ
\*------------------------------------*/

.faq {
    margin-bottom: 110px;

    .brick {
        margin-bottom: 50px;
    }

    @media (max-width: $screen-sm) {
         margin-bottom: 30px;

        .brick {
            margin-bottom: 25px;
        }
    }
}