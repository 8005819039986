/*------------------------------------*
	@COMPONENTS #SOCIAL
\*------------------------------------*/

.social-menu {
	display: flex;
	justify-content: center;
    align-items: center;
    margin-bottom: 15px;    
}

    .social {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 46px;
        height: 46px;
        border-radius: 999px;
        background-color: $grey;

        .icon {
            color: $white;
        }

        &:not(:last-child) {
            margin-right: 20px;
        }

        &:hover {
            opacity: 0.5;
        }

        @media (max-width: $screen-sm) {
            width: 32px;
            height: 32px;
        }
    }

    span.social {
        &:hover {
            opacity: 1;
        }
    }

.social-menu--start {
    justify-content: start;

    @media (max-width: 575px) {	
        justify-content: center;
    }
}