/*------------------------------------*
	@COMPONENTS #TITLE
\*------------------------------------*/

.title-wrap {
	margin-bottom: 40px;
	text-align: center;

	@media (max-width: $screen-sm) {
		margin-bottom: 20px;
	}
}

.title-wrap--alt {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 15px;
}

.title {
	font-size: 18px;
	font-weight: 500;
	color: $black;
	margin-bottom: 20px;

	small {
		font-weight: 400;
		font-size: 18px;
	}

	@media (max-width: $screen-sm) {
		small {
			font-size: 14px;
		}
	}
}

.title--alt {
	@media (max-width: $screen-xs) {
		text-align: center;
	}
}

.title--grey {
	color: $grey-dark;
}

.title--uppercase {
	text-transform: uppercase;
}

.title--bottom {
	margin-bottom: 60px !important;

	@media (max-width: $screen-sm) {	
		margin-bottom: 15px !important;	
	}
}

.title--xs {
	font-size: 16px;
	margin-bottom: 25px;
}

.title--lg {
	font-size: 36px;
	font-weight: 500;

	@media (max-width: $screen-sm) {	
		font-size: 22px;		
	}
}

.title--xl {
	font-size: 48px;
	font-weight: 700;
	line-height: 48px;
	text-align: left;
	text-transform: uppercase;

	@media (max-width: $screen-lg) {	
		font-size: 38px;
	}

	@media (max-width: $screen-sm) {	
		font-size: 32px;
	}

	@media (max-width: $screen-xs) {	
		font-size: 28px;
		line-height: 28px;
		margin-bottom: 10px;
	}
}

.title--green {
	color: $green;
	font-size: 36px;
	font-weight: 800;
	font-style: normal;
	letter-spacing: normal;
	text-align: left;
	margin-bottom: 0;
}

.subtitle {
	color: #797979;
	font-size: 22px;
	font-weight: 400;
	font-style: normal;
	letter-spacing: normal;
	line-height: normal;
	text-align: left;

	@media (max-width: $screen-sm) {	
		font-size: 18px;
	}
}

.text {
	color: $black;
	font-size: 18px;
	font-weight: 500;
	text-align: left;
	margin-bottom: 20px;

	strong {
		font-weight: 800;
	}

	@media (max-width: $screen-sm) {	
		font-size: 16px;
	}

	@media (max-width: $screen-xs) {	
		font-size: 14px;
	}
}