/*------------------------------------*
	@COMPONENTS #DL-PRICE
\*------------------------------------*/

.dl-price {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    line-height: 1;
    margin-bottom: 20px;

    dt {
        font-size: 18px;
        font-weight: 500;
        color: $black;
    }

    dd {
        color: $green;
        font-size: 32px;
        font-weight: 500;
    }
    
    del {
        color: $black;
        font-size: 16px;
        font-weight: 300;
    }

    & + hr {
        margin-top: 20px;
    }

    @media (max-width: 620px) {
         dd {
            font-size: 28px;
        }
    }
}

.dl-price--border {
    margin-bottom: 0px;
    margin-right: 30px;
    padding-right: 30px;
	border-right: 1px solid $border;
}

.dl-price--alt {
    color: $black;
    margin-bottom: 20px;
    margin-right: 0;
    padding-right: 0;
    border: none;

    dt {
        font-size: 16px;
        font-weight: 400;
        color: $black;
    }

    dd {
        color: $black;
        font-weight: 400; 
        
        del {
            color: #959595;
            font-weight: 500;
        }
    }

    @media (max-width: $screen-sm) {
        & + hr {
            margin-top: 15px;
        }
    }

    @media (max-width: 620px) {
        dt {
            font-size: 16px;
            font-weight: 400;
            color: $black;
        }
    }

    @media (max-width: $screen-xs) {
        margin-bottom: 15px;
    }
}

.dl-price--col {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 0;
    margin-right: 30px;
    padding-right: 30px;
	border-right: 1px solid rgba(121, 121, 121, 0.15);
   
    dt {
        font-size: 12px;
        color:$grey-dark;
        margin-bottom: 15px;
    }

    dd {
        display: flex;
        align-items: flex-start;

        del {
            margin-right: 5px;
        }
    }

    @media (max-width: $screen-md) {
        margin-right: 20px;
        padding-right: 20px;
        dd {
            flex-direction: column;
        }
    }
}

.dl-price--shipping {
    margin-bottom: 0;
    flex-direction: column;
    align-items: stretch;

    dt {
        color: $black;
        font-size: 12px;
        margin-bottom: 5px;
    }

    dd {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: $grey-dark;
        font-size: 12px;
        font-weight: 400;

        span {
            background-color: $orange;
            padding: 5px 15px;
            padding-left: 15px;
            padding-right: 15px;
            height: 30px;
            border-radius: 10px;
            color: $white;
            font-size: 14px;
            font-weight: 500;
            display: flex;
            align-items: center;
            margin-right: auto;
        }

        del {
            color: $white;
            font-size: 14px;
            font-weight: 500; 
            margin-right: 5px;
        }

        .icon {
            background-color: $green;
            color: $white;
            width: 20px;
            height: 20px;
            border-radius: 100%;
            padding: 6px;
            margin-right: 10px;
        }
    }
}