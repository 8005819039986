/*------------------------------------*
	@COMPONENTS #WRAP
\*------------------------------------*/

.wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 14px;
}

.wrap--center {
    justify-content: center;
}

.wrap--start {
    justify-content: start;
    align-items: center;

    .social {
        margin-right: 10px;
    }

	@media (max-width: 575px) {
		align-items: center;
        justify-content: center;
	}
}

.wrap--alt {
    flex-direction: column;
}

.wrap--inline {
    display: inline-flex;
    flex-direction: column;
}