/*------------------------------------*
	@COMPONENTS #LAYOUT
\*------------------------------------*/

.page {
	overflow-x: hidden;
}

.main {
	flex: 1 0 auto;
}

.container {
	width: 100%;
	max-width: 1170px;
}

.container--sm {
	max-width: 770px;
}

.container--lg {
	max-width: 970px;
}

address {
	color: $grey-dark;
	font-size: 16px;
	font-weight: 400;
	line-height: 25px;
	text-align: left;
	
	strong {
		font-weight: 400;
		text-transform: uppercase;
	}

	@media (max-width: 575px) {	
		flex-direction: column;
		text-align: center;
	}
}