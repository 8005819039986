/*------------------------------------*
	@COMPONENTS #COLLAPSE
\*------------------------------------*/

.collapse-wrap {
    .dl-price--col {
        display: none;
    }
    
    .split__btn {
        position: absolute;
        top: 0px;
        left: 80px;
        box-shadow: 1px 2px 6px rgba(213, 213, 213, 0.8) !important;
        background-color: #ffffff;
        cursor: default;

        .icon-cart {
            width: 30px !important;
            height: 22px !important;
            color: #959595 !important;
        }
    }
    
    @media (max-width: $screen-sm) {
        display: flex;
        align-items: center;
        padding-top: 15px;
        border-top: 1px solid $border;

        .dl-price--col {
            display: block;
            margin-right: 0;
            padding-right: 0;
            border-right: none;
            margin-left: auto;
        }

        .split__btn {
            position: static;
            transform: none;
            margin-right: 15px;
        }
    }

    @media (max-width: $screen-xs) {
        .split__btn {
            top: 45px;
        }
    }

    .collapse {
        @media (min-width: $screen-sm) {
            display: block !important;
        }
   }

    .collapsed {
        .icon-angle-up {
            transform: rotate(180deg);
        }
    }

    .collapse-trigger {
        padding: 0;
        border-radius: 0;
        color: $grey-dark;
        font-size: 14px;
        text-decoration: none !important;
        position: relative;
        display: flex;
        align-items: center;
    
        span {
            margin-right: 10px;
        }
        
        .icon-angle-up {
            color: $grey-dark;
            width: 10px;
            height: 6px;
            transform: rotate(0);
        }
    
        &:hover {
            color: darken($grey, 10);
        }
    
        @media (min-width: $screen-sm) {
            display: none;
        }
    }
}
