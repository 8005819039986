/*------------------------------------*
	@COMPONENTS #SPLIT
\*------------------------------------*/

.split {
    margin-top: -100px;
    padding-top: 120px;  
    padding-bottom: 70px;  
    display: flex;
    position: relative;

    &::before {
        content: '';
        background-color: $grey-light;
        width: 50vw;
        height: 100%;
        position: absolute;
        top: 0px;
        left: 50%;
        z-index: -1;
    }

    @media (max-width: 620px) {
        flex-direction: column;
        padding-bottom: 0px;  

        .title {
            display: none;
        }

        &::before {
            display: none;
        }
    }
}

.split--alt {
    padding-bottom: 0;
    position: relative;

    &::before {
        display: none;
    }

    @media (max-width: $screen-sm) {
        flex-direction: column;
        align-items: center;
        padding-top: 100px;

        hr {
            margin-top: 15px;
            margin-bottom: 40px;
        }
    }
}

.split--lg {
    padding-top: 150px;
    margin-top: 0;

    .split__content {
        .wrap--center {
            display: none;

            @media (max-width: $screen-md) {	
                display: flex;
                flex-direction: column;
                align-items: center;
                padding-top: 20px;
                padding-bottom: 20px;
            }
        }
    }

    @media (max-width: $screen-md) {
        flex-direction: column;
        align-items: center;
        padding-top: 40px;
        padding-bottom: 0;

        &::before {
           display: none;
        }

        .wrap {
            align-items: flex-start;

            .title--green {
                font-size: 24px;
                margin-bottom: 20px;
                margin-right: 30px;
            }

            .icon-star {
                width: 16px;
            }
        }

        .wrap--inline {
            align-items: center;
        }
    }

    @media (max-width: 620px) {
        padding-top: 20px;

        .title {
            display: inline-flex !important;
        }

        .title--green {
            margin-bottom: 0 !important;
            font-size: 18px !important;
        }

        .split__content {
            padding-bottom: 0 !important;

            img {
                width: 280px;
            }

            .wrap--center {
                padding-top: 0;
                padding-bottom: 10px;
            }
        }
    }
}
    .split__btn {
        width: 66px;
        height: 66px;
        border-radius: 100%;
        box-shadow: 1px 2px 6px rgba(213, 213, 213, 0.8);
        background-color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 70px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 99;

       .icon-cart {
            width: 30px !important;
            height: 22px !important;
            color: #959595;
        }

        &:hover {
            cursor:default;

            @media (max-width: $screen-xs) {
                box-shadow: 1px 2px 6px rgba(213, 213, 213, 0.3);
                cursor: pointer;

                .icon-cart {
                    color: $green !important;
                }
            }
        }

        @media (max-width: 620px) {
            top: 0;
            transform: translate(-50%, -50%);
        }
    }

    .split__content {
        padding-right: 100px;
        width: 50%;

        &:last-child {
            padding-left: 100px;
            padding-right: 0;
        }

        @media (max-width: $screen-lg) {
            padding-right: 30px;
            padding-left: 30px;

            &:last-child {
                padding-right: 30px;
                padding-left: 30px;
            }
        }

        @media (max-width: $screen-md) {
            width: 100%;
            max-width: 480px;

            .split--lg & {
                padding-right: 0;
                padding-left: 0;
            }
        }

         @media (max-width: $screen-xs) {
            padding-right: 0px;
            padding-left: 0px;
            max-width: 100%;

            &:last-child {
                padding-right: 15px;
                padding-left: 15px;
            }
        }

        @media (max-width: 620px) {
            padding-right: 0px;
            padding-bottom: 60px;
            width: 100%;

            &:last-child {
                position: relative;
                width: 100%;
                padding-left: 20px;
                padding-right: 20px;
                padding-bottom: 30px;
                background-color: $grey-light;
                padding-top: 40px;
                margin-right: -15px;
                margin-left: -15px;
                min-width: calc(100% + 30px);
            }
        }
    }

    .split__sticky {
        position: sticky;
        top: 150px;

        hr {
            margin-top: 30px;
            margin-bottom: 30px;
        }

        @media (max-width: $screen-md) {	
            padding-bottom: 30px;
        }

        @media (max-width: $screen-xs) {	
            display: flex;
            flex-direction: column;
            align-items: center;

            .list {
                text-align: left;
            }

            .title {
                text-align: center;
            }

            .subtitle {
                text-align: center;
            }

            .text {
                margin-bottom: 10px !important;
                text-align: center !important;
            }

            .wrap--inline {
                display: flex;
                justify-content: center;
                align-items: center;
            }

            hr {
                width: 100%;
                height: 1px;
            }
        }
    }

    .split__content--alt {
        display: block;
        box-sizing: border-box;
        
        @media (max-width: $screen-md) {
            position: relative;	
            padding-top: 40px;

            &::before {
                content: '';
                background-color: $grey-light;
                width: 100vw;
                height: 100%;
                @include centerH;
                top: 0;
                z-index: -1;
            }
        }

        @media (max-width: $screen-sm) {	
            .text {
                margin-bottom: 0px;
            }
        }
    }

    .split__img {
        width: 100%;
    }