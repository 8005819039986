/*------------------------------------*
	@COMPONENTS #TESTIMONIALS
\*------------------------------------*/

.testimonials {
	padding-top: 110px;
	padding-bottom: 110px;

	@media (max-width: $screen-sm) {
		padding-top: 70px;
		padding-bottom: 30px;
	}
}

.testimonials--bottom {
	@media (max-width: $screen-sm) {	
		margin-bottom: 40px;

		.testimonials__img {
			padding-top: 30px;
		}
	}
}

	.testimonials__img {
		padding-top: 100px;
		margin-bottom: 30px;

		img {
			width: 100%;
			height: 370px;
		}

		@media (max-width: $screen-sm) {	
			padding-top: 60px;
			margin-bottom: 20px;

			img {
				height: 300px;
			}
		}

		@media (max-width: $screen-xs) {	
			img {
				height: 100%;
				min-height: 150px;
			}
		}
	}