/*------------------------------------*
	@COMPONENTS #AVATAR
\*------------------------------------*/

.avatar {
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 100%;
	background-color: $white;

	img {
		width: 132px;
		height: 132px;
		border-radius: 50%;
		margin-bottom: 0;
	}

	.testimonials & {
		margin-right: 34px;
	}

	@media (max-width: $screen-sm) {
		img {
			width: 76px;
			height: 76px;
		}
		.testimonials & {
			margin-right: 12px;
		}
	}
}