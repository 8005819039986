/*------------------------------------*
	@COMPONENTS #CARD
\*------------------------------------*/

.card-wrap {
    display: flex;
    padding-top: 40px;
}

.card-wrap--alt {
    margin-left: -15px;
    margin-right: -15px;
    margin-bottom: 40px;
    padding-top: 0;

    > * {
        padding-left: 15px;
        padding-right: 15px;
    }

    @media (max-width: $screen-sm) {	
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 0px;


        > * {
            padding-left: 15px;
            padding-right: 15px;
            margin-bottom: 30px;
        }
    }
}

.card {
    padding: 40px 70px 80px 70px;
    max-width: 570px;
    width: 100%;
    background-color: #f8f7f9;
    border: none;

    .title {
        &:not(:first-child) {
            margin-top: 70px;
        }
    }

    @media (max-width: $screen-md) {
        padding: 25px 30px 30px 30px;
    }

    @media (max-width: $screen-sm) {
        order: 2;
    }

    @media (max-width: $screen-xs) {
        margin-right: -15px;
        margin-left: -15px;
        padding-left: 20px;
        padding-right: 20px;
        min-width: calc(100% + 30px);

        .form {
            .title {
                display: block;
                margin-top: 0px;
            }
            
            .title--alt {
                margin-top: 40px;
            }
        }
    }
}

.card--nobackground {
    background-color: transparent;
    padding-top: 0px;
    padding-right: 0;

    @media (max-width: $screen-sm) {
        order: 1;
        padding-left: 0;
        padding-right: 0;
    }

    @media (max-width: $screen-xs) {
        padding-left: 15px;
        padding-right: 15px;
    }
}

.card-over {
    display: flex;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    border: 1px solid $grey;
    background-color: $white;
    max-width: 380px;
    width: 100%;
    position: fixed;
    bottom: 30px;
    transform: translateX(-200%);
    left: 0;
    z-index: 1200;
    transition: all 1.2s ease;
    
    &.card-over-on {
        transform: translateX(0);
    }

    .card__cover {
        img {
            border-radius: 10px 0 0 10px;
            height: 118px;
        }
    }

    .card__content {
        padding: 20px;
        display: flex;
        flex-direction: column;
        justify-content: start;
    }

    .card__title {
        color: $black;
        font-size: 16px;
        font-weight: 500;
        line-height: 1;
        text-align: left;
        margin-bottom: 10px;
    }

    .card__text {
        color: $grey-dark;
        font-size: 14px;
        font-weight: 400;
        line-height: 1;
        text-align: left;
        margin-bottom: 0px;
    }

    .card__text--green {
        color: $green;
        margin-top: auto;
    }

    @media (max-width: $screen-sm) {	
        max-width: 300px;
        width: 100%;

        .card__cover {
            img {
                border-radius: 10px 0 0 10px;
                height: 80px;
            }
        }

        .card__content {
            padding: 8px;
        }

        .card__title {
            margin-bottom: 6px;
        }

        &.card-over-on {
            left: 10px
        }
    }

    @media (max-width: $screen-xs) {
        
    }
}

.card--alt {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: transparent;
    padding: 0 15px;

    .card__content {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: 30px;
    }

    img {
        width: 100%;
        border-radius: 5px;
    }

    .card__title {
        color: $black;
        font-size: 18px;
        font-weight: 800;
        text-align: left;
        margin-bottom: 10px;
    }

    .card__text {
        color: $grey-dark;
        font-size: 14px;
        font-weight: 400;
        line-height: 1.5;
        text-align: left;
        margin-bottom: 0;
    }

    @media (max-width: $screen-sm) {	
        max-width: 420px;
        flex-direction: column-reverse;
        margin-bottom: 40px;

        .card__content {
            margin-bottom: 0px;
        }

        img {
            margin-bottom: 20px;
        }
    }

    @media (max-width: $screen-xs) {
        align-items: center;
        min-width: 280px !important;

        .card__content {
            align-items: center;
        }

        .card__title,
        .card__text {
            text-align: center;
        }
    }
}