/*------------------------------------*
	@COMPONENTS #HEADER
\*------------------------------------*/

.header {
	padding-top: 30px;
	padding-bottom: 30px;
	z-index: 1000;

	@media (max-width: $screen-sm) {
		padding-top: 20px;
		padding-bottom: 20px;
	}
}

.header--inner {
	position: relative;
	padding: 0;

	.container {
		position: static;
		display: flex;
		align-items: center;
	}
	
	&::before {
		content: '';
		position: absolute;
		box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
		width: 100%;
		height: 100%;
		background-color: $white;
		top: 0;
		left: 0;
		z-index: 200;
	}

	.header-menu__trigger {
		display: flex;
		position: absolute;
		top: 50%;
		right: 15px;
		transform: translateY(-50%);
		margin-bottom: 0 !important;
		margin-right: 0 !important;
		@include hide;
		z-index: 200;	

		svg {
			color: $grey-dark;
		}

		@media (max-width: $screen-sm) {	
			@include show;
		}
	}

	.header__content {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 74px;
		position: relative;
		flex: 1;
	
		&:not(:last-child) {
			margin-right: 30px;
		}
	
		.btn {
			margin-bottom: 0 !important;
		}
	
		@media (max-width: $screen-sm) {	
			justify-content: center;
		}

		@media (max-width: $screen-xs) {	
			height: 60px;
		}
	}

	.header__logo {
		height: 40px;
		position: relative;
		z-index: 200;

		display: flex;
		flex-direction: column;
		align-items: center;
		text-decoration: none;

		img {
			margin-bottom: 0;
		}

		span {
			color: $text-clr;
			margin-top: 10px;
			font-size: 12px;

			@media (min-width: $screen-sm + 1) {
				display: none;
			}
		}
	
		&:hover {
			opacity: 0.5;
		}
	
		@media (max-width: $screen-sm) {
			img {
				height: 24px;
			}
		}

		@media (max-width: $screen-xs) {	
			span {
				margin-top: 5px;
			}
		}
	}
	
	.header__menu {
		display: flex;
		align-items: center;
		margin-left: auto;
		margin-right: 30px;
		position: relative;
		z-index: 200;
	
		> * {
			&:not(:last-child) {
				margin-right: 30px;
			}
		}
	
		a {
			font-size: 18px;
			font-weight: 400;
			font-style: normal;
			letter-spacing: 0.04px;
			color: $black;
			text-decoration: none;
	
			&:hover {
				opacity: 0.5;
			}
		}
	
		@media (max-width: $screen-sm) {	
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			margin-left: 0px;
			margin-right: 0px;
			background-color: $white;
			padding-top: 20px;
			padding-bottom: 20px;
			
			position: absolute;
			top: 100%;
			transform: translateY(-200%);
			left: -15px;
			width: 100vw;
			
			transition: all 0.8s ease;
			border-bottom: 1px solid $grey;
			text-align: center;
			z-index: 90;
			overflow-y: auto;
			
			> * {
				&:not(:last-child) {
					margin-right: 0px;
					margin-bottom: 10px;
				}
			}
			
			.menu-on &  {
				transform: translateY(0);
				border-radius: 0;

				a {
					font-size: 16px;
				}
			}
		}
	}
}

