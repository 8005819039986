//------------------------------------\\
//  @SETTINGS #VARIABLES
//------------------------------------//

//COLORS
$red: #ff6d6c;
$green: #1acd8e;
$orange: #ffc260;
$grey: #c9c9c9;
$grey-light: #f8f7f9; 
$grey-dark: #797979;
$white: #fff;
$black: #000;


$primary: 					$grey-dark;
$alt: 						$black;

// Backgrounds
$bgr: 						#fff;
$base-bgr:                  #fff;


$base-font:					'Quicksand', sans-serif;		

$base-font-size:            16px;
$base-line-height:         	1.2;
$base-font-weight:          400;

$text-clr: 					$grey-dark;
$text-alt: 					$black;
$placeholder-clr:			#ccc;
$link-clr:					$primary;
$link-hover:				darken($primary,10);

$border:					#dbdbdb;;
$borderd:					#eeeeee;



// Layout
$space:                     30px;
$radius:					3px;


// Breakpoints
$screen-xs:                 560px;
$screen-sm:                 768px;
$screen-md:                 992px;
$screen-lg:                 1200px;

$breakpoint:     			991px !default;

// Select Text
$selected-text-bgr:			#333;
$selected-text-clr:			#fff;