/*------------------------------------*
	@COMPONENTS #PRODUCT
\*------------------------------------*/

.product {
    display: flex;
    padding-bottom: 100px;
    margin-bottom: 100px;
    border-bottom: 1px solid #dbdbdb;

    &:last-child {
        padding-bottom: 40px;
        margin-bottom: 0;
        border-bottom: none;
    }

    @media (max-width: $screen-md) {	
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-bottom: 60px;
        margin-bottom: 60px;
    }
    
    @media (max-width: $screen-sm) {	
        padding-bottom: 40px;
        margin-bottom: 40px;
    }
}

.product-images {
    display: flex;
    justify-content: start;
    flex-direction: column;
    align-items: start;
    margin-bottom: 20px;

    img {
        margin-bottom: 40px;
        max-width: 100%;
    }

    @media (max-width: $screen-md) {	
        display: none;
    }
}

.product--reverse {
    .product__content {
        margin-right: 0;
        margin-left: 30px;
    }
    
    @media (max-width: $screen-md) {	
        flex-direction: column-reverse;

        .product__content {
            margin-left: 0;
        }
    }
}

    .carousel-wrap {
        .product__large {
            width: 100%;
            margin: 0 auto;
            position: relative;
            z-index: 10;

            img {
                margin-bottom: 20px;
                box-sizing: border-box;
                border-radius: 5px;
            }
        }

        .product__thumbs {
            display: none;

            img {
                text-align: center;
                box-sizing: border-box;
                cursor: pointer;
                margin-left: 10px;
                margin-right: 10px;
                border: 1px solid $grey;
                border-radius: 5px;
            }

            .slick-slide {
                opacity: .5;
                @include trans;

                &.slick-current {
                    opacity: 1;
                }
            }

            .slick-dots {
                display: flex;
                padding-left: 0;
                position: absolute;
                bottom: 0px;
                left: 50%;
                transform: translateX(-50%);
                list-style: none;

                button {
                    background-color: $grey;
                    width: 10px;
                    height: 10px;
                    padding: 0;
                    border-radius: 999px;
                }
                
                li {
                    position: relative;
                    color: transparent;
                    background-color: transparent;
                    font-size: 0;

                    &:not(:last-child) {
                        margin-right: 20px;
                    }
                }

                .slick-active {
                    button {
                        background-color: $grey-dark;
                    }
                }
            }

            @media (max-width: $screen-md) {	
                display: block;
            }

            @media (max-width: $screen-xs) {	
                .slick-dots {
                    bottom: 10px;

                    li {
                        bottom: -15px;
                        &:not(:last-child) {
                            margin-right: 10px;
                        }
                    }
                }
            }
        }
    }

    .product__cover {
        img {
            border-radius: 5px;
        }

        @media (max-width: $screen-sm) {	
            img {
                max-height: 280px;
                height: 100%;
            }
        }

        @media (max-width: $screen-xs) {	
            img {
                max-height: 240px;
            }
        }

        @media (max-width: 350px) {	
            img {
                max-height: 200px;
            }
        }
    }

    .product__content {
        margin-right: 30px;

        @media (max-width: $screen-sm) {	
            margin-right: 0;
        }
    }

    .product__title {
        color: $black;
        font-size: 48px;
        font-weight: 700;
        line-height: 1;
        text-align: left;
        margin-bottom: 20px;

        @media (max-width: $screen-md) {	
            font-size: 36px;
        }

        @media (max-width: $screen-sm) {	
            font-size: 28px;
        }
        
        @media (max-width: $screen-xs) {	
            text-align: center;
            font-size: 26px;
        }
    }

    .product__text {
        color: $grey-dark;
        font-size: 22px;
        font-weight: 400;
        line-height: normal;

        @media (max-width: $screen-md) {	
            font-size: 18px;
            margin-bottom: 20px !important;
        }

        @media (max-width: $screen-xs) {	
            font-size: 16px;
            text-align: center;
        }
    }