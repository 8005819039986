/*------------------------------------*
	@COMPONENTS #BOX
\*------------------------------------*/

.box-wrap {
    margin-bottom: 40px;

    > * {
        @media (max-width: $screen-sm) {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }

    @media (max-width: $screen-sm) {
        margin-bottom: 10px;
    }
}

.box {
    box-shadow: 1px 2px 6px rgba(213, 213, 213, 0.8);
    border-radius: 10px;
    background-color: #ffffff;
    width: 100%;
    max-width: 470px;
    padding: 50px 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;
    position: relative;

    @media (max-width: $screen-sm) {
        padding: 30px 30px;
        margin-bottom: 10px;
    }
}
    .box__img {
        margin-bottom: 40px;
    }

    .box__btn {
        margin-bottom: 10px;
    }