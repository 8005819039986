/*------------------------------------*
	@COMPONENTS #COPYRIGHT
\*------------------------------------*/

.copyright {
    padding-top: 10px;
    padding-bottom: 10px;
    width: 100%;
    border-top: 1px solid #dbdbdb;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: $screen-xs) {
        font-size: 10px;
        letter-spacing: 0.02px;
    }
}

.copyright--space {
    padding-top: 30px;
    padding-bottom: 30px;
    justify-content: space-between;

    @media (max-width: 620px) {
        flex-direction: column;	
        justify-content: center;
        padding-top: 15px;
        padding-bottom: 15px;
        
        p {
            font-size: 12px;
         }
    }
}

.copyright__list {
    display: flex;
    padding-left: 0;
    list-style: none;
    margin-bottom: 0;

    li {
        position: relative;

        &:not(:last-child) {
            margin-right: 14px;
            
            &::before {
                content: '';
                background-color: $grey;
                width: 1px;
                height: 10px;
                position: absolute;
                top: 5px;
                right: -7px;
            }
        }

        a {
            font-size: 14px;
            color: $grey-dark;
            text-decoration: none;
            line-height: 1;

            &:hover {
                opacity: 0.5;
            }
        }
    }

    @media (max-width: $screen-xs) {
        margin-bottom: 10px;	
        li {
            a {
                font-size: 12px;
            }
        }
    }
}