/*------------------------------------*
	@COMPONENTS #CAROUSEL
\*------------------------------------*/

.carousel {
	position: relative;
}

	.slick-arrow {
		position: absolute;
		top: -45px;
		z-index: 1;
		
		.icon {
			width: 10px;
			height: 18px;
			color: $grey;
		}

		&:hover {
			.icon {
				color:$green;
			}
		}

		@media (max-width: $screen-xs) {	
			top: -20px;
		}
	}

	.slick-arrow--prev {
		right: 25px;
	}

	.slick-arrow--next {
		right: 0px;
	}