/*------------------------------------*
	@COMPONENTS #SMALL
\*------------------------------------*/

.small {
    display: flex;
    align-items: center;
    font-size: 12px;
    margin-bottom: 5px;

    dt {
        color: $grey-dark;
        font-weight: 400;
    }

    dd {
        color: $green;
        font-weight: 500;
        padding-left: 2px;
    }
}