/*------------------------------------*
	@COMPONENTS #CARD
\*------------------------------------*/

.section {
    padding-top: 100px;
    padding-bottom: 100px;

    @media (max-width: $screen-md) {	
        padding-top: 60px;
        padding-bottom: 60px;
    }

    @media (max-width: $screen-sm) {	
        padding-top: 40px;
        padding-bottom: 40px;
    }
}

.section--bordered {
    box-shadow: -3px 1px 12px rgba(0, 0, 0, 0.07);
    box-shadow: -3px 1px 12px rgba(0, 0, 0, 0.07);
}