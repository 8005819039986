/*------------------------------------*\
	@COMPONENTS #MODAL
\*------------------------------------*/

.modal {
	background-color: transparent;
	transition: all .8s ease;

	@media (max-width: $screen-sm) {
		padding-left: 10px;
		padding-right: 10px !important;
	}
}

	.modal-backdrop {
		box-shadow: 1px 2px 14px #959595;
		border-radius: 10px;
		background-color: rgba(0, 0, 0, 0.25);
	}

	.modal-dialog {
		max-width: 520px;
		margin-top: 260px;
		margin-left: auto;
		margin-right: auto;


		@media (max-width: $screen-sm) {
			margin-top: 240px;
		}
	}

	.modal-content {
		background-color: $white;
		padding: 40px;
		display: flex;
		flex-direction: column;
		align-items: center;
		box-shadow: 1px 2px 14px #959595;
		border-radius: 10px;
		background-color: #ffffff;

		hr {
			min-width: 100%;
			margin-top: 20px;
			margin-bottom: 20px;
		}

		img {
			margin-bottom: 40px;
		}

		@media (max-width: $screen-sm) {
			padding: 30px 20px;

			hr {
				margin-top: 15px;
				margin-bottom: 15px;
			}

			img {
				margin-bottom: 30px;
			}
		}
	}

	.modal__title {
		color: $black;
		font-size: 32px;
		font-weight: 500;
		margin-bottom: 30px;

		@media (max-width: $screen-sm) {
			font-size: 24px;
			margin-bottom: 25px;
		}
	}

	.modal__text {
		color: $grey-dark;
		font-size: 16px;
		font-weight: 400;
		margin-bottom: 0px;
		line-height: 1.55;
		max-width: 370px;
		text-align: center;

		span {
			color: $red;
			font-weight: 500;
			text-transform: uppercase;
		}

		strong {
			color: $black;
			font-weight: 500;
			text-transform: uppercase;
		}

		@media (max-width: $screen-sm) {
			font-size: 14px;
		}
	}

	.modal-content .checkbox {
		margin-bottom: 30px !important;

		@media (max-width: $screen-sm) {
			margin-bottom: 25px !important;
		}
	}

	.modal-content .checkbox__label {
		max-width: 325px;
		text-align: left;
		margin-bottom: 0 !important;

		@media (max-width: $screen-sm) {
			font-size: 10px;
			padding-left: 40px;

			&::before {
				left: 10px;
			}

			&::after {
				left: 13px;
			}
		}
	}