/*------------------------------------*
	@TRUMPS #COLORS
\*------------------------------------*/

.color-primary {
	color: $primary !important;
}

.color-alt {
	color: $alt !important;
}
