/*------------------------------------*
	@COMPONENTS #BRICK
\*------------------------------------*/

.brick {
    display: flex !important;
    margin-right: 70px;
    outline: none;

    @media (max-width: $screen-md) {
        margin-right: 0;
    }
}

    .brick__title {
        color: $black;
        font-size: 22px;
        font-weight: 500;
        margin-bottom: 10px;

        @media (max-width: $screen-md) {
            font-size: 14px;
        }
    }

    .brick__text {
        color: $grey-dark;
        line-height: 1.5;

        @media (max-width: $screen-sm) {
            font-size: 14px;
        }
    }