/*------------------------------------*
    @OBJECTS #ICONS
\*------------------------------------*/

.icon {
    fill: currentColor;
    height: 24px;
    width: 24px;
    vertical-align: middle;
    display: inline-block;
    @include trans;

    .btn & {
        &:last-child {
	        margin-left: 10px;
        }
        &:first-child {
	        margin-right: 10px;
        }
        &:only-child {
            margin: 0;
        }
    }
}