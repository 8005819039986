@charset "UTF-8";
/* -----------------------------------------------------
+
+ FRONT-MAN.com
+
------------------------------------------------------ */
/*------------------------------------*
    @BASE #PAGE
\*------------------------------------*/
html {
  min-height: 100%;
  height: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

body {
  font-family: "Quicksand", sans-serif;
  font-size: 16px;
  line-height: 1.2;
  font-weight: 400;
  background-color: #fff;
  color: #797979;
  min-width: 320px;
  min-height: 100vh;
  position: relative;
  display: flex;
  display: -ms-flexbox;
  flex-direction: column;
}

body * {
  box-sizing: border-box;
}

::-moz-selection {
  background: #333;
  color: #fff;
  text-shadow: none;
}

::selection {
  background: #333;
  color: #fff;
  text-shadow: none;
}

/*------------------------------------*
    @BASE #TYPE
\*------------------------------------*/
h1, .h1 {
  font-size: 2.25rem;
}

h2, .h2 {
  font-size: 1.5rem;
}

h3, .h3 {
  font-size: 1.25rem;
}

h4, .h4 {
  font-size: 1.125rem;
}

h5, .h5 {
  font-size: 1rem;
}

h6, .h6 {
  font-size: 0.875rem;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  line-height: 1;
}

h1:first-child, h2:first-child, h3:first-child, h4:first-child, h5:first-child, h6:first-child,
.h1:first-child, .h2:first-child, .h3:first-child, .h4:first-child, .h5:first-child, .h6:first-child {
  margin-top: 0;
}

h1:last-child, h2:last-child, h3:last-child, h4:last-child, h5:last-child, h6:last-child,
.h1:last-child, .h2:last-child, .h3:last-child, .h4:last-child, .h5:last-child, .h6:last-child {
  margin-bottom: 0;
}

dl dd {
  margin-bottom: 0;
}

img,
p,
ul,
ol,
dl,
pre,
blockquote {
  margin-bottom: 30px;
}

img:last-child,
p:last-child,
ul:last-child,
ol:last-child,
dl:last-child,
pre:last-child,
blockquote:last-child {
  margin-bottom: 0;
}

hr {
  background-color: none;
  border: none;
  border-bottom: 1px solid #dbdbdb;
  height: 1px;
  margin: 40px 0;
}

@media (max-width: 620px) {
  hr {
    margin: 20px 0 30px 0;
  }
}

/*------------------------------------*
    @BASE #LINKS
\*------------------------------------*/
a {
  outline: none !important;
  /* removing outline in Mozilla */
  text-decoration: none;
  cursor: pointer;
  transition: all .3s ease 0s;
}

/*------------------------------------*
    @OBJECTS #BUTTONS
\*------------------------------------*/
button {
  cursor: pointer;
  transition: all .3s ease 0s;
}

button, button:focus, button:active {
  outline: none !important;
}

button:not(.btn), button:focus:not(.btn), button:active:not(.btn) {
  border: none;
}

*[class*="btn-"] {
  user-select: none;
  display: inline-block;
  vertical-align: middle;
  font: inherit;
  font-family: "Quicksand", sans-serif;
  text-align: center;
  text-transform: none;
  margin: 0;
  cursor: pointer;
  overflow: visible;
  transition: all .3s ease 0s;
  border-radius: 0;
  border: none;
  padding: 0 20px;
}

*[class*="btn-"], *[class*="btn-"]:hover, *[class*="btn-"]:active, *[class*="btn-"]:focus {
  text-decoration: none;
}

/*------------------------------------*
    @OBJECTS #TABLES
\*------------------------------------*/
table,
.table {
  width: 100%;
}

table th,
.table th {
  font-weight: normal;
}

/*------------------------------------*
    @OBJECTS #FORMS
\*------------------------------------*/
.form-control {
  vertical-align: middle;
  transition: all .3s ease 0s;
}

textarea.form-control {
  padding: 10px 15px;
  resize: none;
  min-height: 150px;
  line-height: 1.2;
}

input[type='number'] {
  -moz-appearance: textfield;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/*------------------------------------*
    @OBJECTS #ICONS
\*------------------------------------*/
.icon {
  fill: currentColor;
  height: 24px;
  width: 24px;
  vertical-align: middle;
  display: inline-block;
  transition: all .3s ease 0s;
}

.btn .icon:last-child {
  margin-left: 10px;
}

.btn .icon:first-child {
  margin-right: 10px;
}

.btn .icon:only-child {
  margin: 0;
}

/*------------------------------------*
    @OBJECTS #CAROUSEL
\*------------------------------------*/
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/*------------------------------------*
	@COMPONENTS #LAYOUT
\*------------------------------------*/
.page {
  overflow-x: hidden;
}

.main {
  flex: 1 0 auto;
}

.container {
  width: 100%;
  max-width: 1170px;
}

.container--sm {
  max-width: 770px;
}

.container--lg {
  max-width: 970px;
}

address {
  color: #797979;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  text-align: left;
}

address strong {
  font-weight: 400;
  text-transform: uppercase;
}

@media (max-width: 575px) {
  address {
    flex-direction: column;
    text-align: center;
  }
}

/*------------------------------------*
	@COMPONENTS #CHECKBOX
\*------------------------------------*/
.checkbox {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 40px;
}

.checkbox input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

@media (max-width: 620px) {
  .checkbox {
    margin-bottom: 20px;
  }
}

.checkbox__label {
  color: #797979;
  font-size: 12px;
  font-weight: 400;
  position: relative;
  padding-left: 30px;
  margin-bottom: 0;
  transition: all .3s ease 0s;
}

input:checked + .checkbox__label {
  color: #000;
}

.checkbox__label::before, .checkbox__label::after {
  content: '';
  position: absolute;
  transition: all .3s ease 0s;
}

.checkbox__label::before {
  width: 18px;
  height: 18px;
  border-radius: 2px;
  border: 1px solid #979797;
  background-color: #fff;
  top: 0;
  left: 0;
}

.checkbox__label::after {
  content: '';
  background-image: url(../images/icon-check.svg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 0;
  width: 12px;
  height: 9px;
  top: 5px;
  left: 3px;
  opacity: 0;
}

input:checked + .checkbox__label::before {
  background-color: #fff;
  border: 1px solid #1acd8e;
}

input:checked + .checkbox__label::after {
  opacity: 1;
}

@media (max-width: 620px) {
  .checkbox__label {
    font-size: 10px;
  }
}

/*------------------------------------*
	@COMPONENTS #FLAG
\*------------------------------------*/
.flag-wrap {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
  position: relative;
}

.flag-wrap:last-of-type {
  margin-bottom: 20px !important;
}

.flag-wrap input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

@media (max-width: 620px) {
  .flag-wrap {
    margin-bottom: 10px;
  }
  .flag-wrap:last-of-type {
    margin-bottom: 15px !important;
  }
}

.flag {
  width: 100%;
  background-color: #fff;
  box-shadow: 1px 2px 6px rgba(213, 213, 213, 0.8);
  border-radius: 10px;
  margin-bottom: 0;
  display: flex;
  justify-content: space-between;
  border: 1px solid transparent;
  transition: all .3s ease 0s;
}

.flag .icon-cart {
  width: 24px;
  height: 23px;
  color: #c9c9c9;
  position: absolute;
  top: 54px;
  left: 15px;
}

.flag-wrap input:checked + .flag {
  box-shadow: 1px 2px 14px #c8bfbf;
  border-radius: 10px;
  border: 1px solid #1acd8e;
}

.flag-wrap input:checked + .flag .icon-cart {
  color: #1acd8e;
}

.flag--alt {
  box-shadow: none;
  padding-top: 100px;
  margin-bottom: 90px;
}

.flag--alt .flag__content {
  padding-left: 0;
  padding-right: 0;
}

.flag--alt .flag__content .dl-price--col {
  margin-bottom: 25px;
  padding-right: 0px;
  border-right: none;
}

.flag--alt .flag__content .dl-price--col dt {
  color: #000;
  font-size: 14px;
}

.flag--alt .flag__content .dl-price--col dd {
  flex-direction: column;
}

.flag--alt .flag__content .flag__img {
  flex-shrink: 1;
}

@media (max-width: 992px) {
  .flag--alt .flag__img {
    padding-right: 15px;
    margin-right: 15px;
  }
}

@media (max-width: 768px) {
  .flag--alt {
    padding-top: 40px;
    margin-bottom: 40px;
  }
  .flag--alt .flag__img img {
    width: 100%;
  }
}

.flag__check {
  width: 24px;
  height: 24px;
  border-radius: 100%;
  box-shadow: inset 1px 1px 2px 2px rgba(228, 228, 228, 0.5);
  background-color: #fff;
  position: absolute;
  top: 15px;
  left: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all .3s ease 0s;
}

.flag__check .icon-check {
  width: 11px;
  height: 8px;
  color: transparent;
}

input:checked + .flag .flag__check {
  background-color: #1acd8e;
  box-shadow: none;
}

input:checked + .flag .flag__check .icon-check {
  color: #fff;
}

.flag__img {
  border-right: 1px solid rgba(121, 121, 121, 0.15);
  padding-right: 30px;
  margin-right: 30px;
  margin-bottom: 0;
}

.flag__img img {
  max-width: 200px;
}

.flag__content {
  padding: 15px 30px 15px 70px;
  flex: 1;
  display: flex;
  align-items: flex-end;
}

.flag__promo {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: -5px;
}

.flag__promo > * {
  margin: 5px;
}

.flag__promo span {
  font-size: 12px;
  font-weight: 500;
  color: #000;
  padding: 5px 15px;
  border-radius: 10px;
  background-color: #f8f7f9;
  white-space: nowrap;
}

.flag__promo strong {
  color: #ff6d6c;
  margin-left: 5px;
}

.flag__promo small {
  color: #797979;
  font-size: 12px;
  font-weight: 300;
}

.flag__ribbon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  top: 15px;
  right: -6px;
  position: absolute;
  width: 70px;
  height: 40px;
  background-color: #ffc260;
  border-radius: 10px 0 0 10px;
}

.flag__ribbon p {
  color: #fff;
  font-size: 10px;
  font-weight: 900;
  line-height: 10px;
  text-transform: uppercase;
  margin-bottom: 3px;
}

.flag__ribbon span {
  display: flex;
}

.flag__ribbon span .icon {
  width: 6px;
  height: 6px;
}

.flag__ribbon::before {
  color: #ffc260;
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-color: transparent;
  -moz-transform: scale(0.9999);
  border-width: 7px 0 7px 7px;
  border-left-color: currentColor;
  position: absolute;
  top: -6px;
  right: 0px;
}

/*------------------------------------*
	@COMPONENTS #HEADER
\*------------------------------------*/
.header {
  padding-top: 30px;
  padding-bottom: 30px;
  z-index: 1000;
}

@media (max-width: 768px) {
  .header {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

.header--inner {
  position: relative;
  padding: 0;
}

.header--inner .container {
  position: static;
  display: flex;
  align-items: center;
}

.header--inner::before {
  content: '';
  position: absolute;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 100%;
  background-color: #fff;
  top: 0;
  left: 0;
  z-index: 200;
}

.header--inner .header-menu__trigger {
  display: flex;
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  margin-bottom: 0 !important;
  margin-right: 0 !important;
  opacity: 0;
  visibility: hidden;
  z-index: 200;
}

.header--inner .header-menu__trigger svg {
  color: #797979;
}

@media (max-width: 768px) {
  .header--inner .header-menu__trigger {
    opacity: 1;
    visibility: visible;
  }
}

.header--inner .header__content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 74px;
  position: relative;
  flex: 1;
}

.header--inner .header__content:not(:last-child) {
  margin-right: 30px;
}

.header--inner .header__content .btn {
  margin-bottom: 0 !important;
}

@media (max-width: 768px) {
  .header--inner .header__content {
    justify-content: center;
  }
}

@media (max-width: 560px) {
  .header--inner .header__content {
    height: 60px;
  }
}

.header--inner .header__logo {
  height: 40px;
  position: relative;
  z-index: 200;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
}

.header--inner .header__logo img {
  margin-bottom: 0;
}

.header--inner .header__logo span {
  color: #797979;
  margin-top: 10px;
  font-size: 12px;
}

@media (min-width: 769px) {
  .header--inner .header__logo span {
    display: none;
  }
}

.header--inner .header__logo:hover {
  opacity: 0.5;
}

@media (max-width: 768px) {
  .header--inner .header__logo img {
    height: 24px;
  }
}

@media (max-width: 560px) {
  .header--inner .header__logo span {
    margin-top: 5px;
  }
}

.header--inner .header__menu {
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: 30px;
  position: relative;
  z-index: 200;
}

.header--inner .header__menu > *:not(:last-child) {
  margin-right: 30px;
}

.header--inner .header__menu a {
  font-size: 18px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0.04px;
  color: #000;
  text-decoration: none;
}

.header--inner .header__menu a:hover {
  opacity: 0.5;
}

@media (max-width: 768px) {
  .header--inner .header__menu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 0px;
    margin-right: 0px;
    background-color: #fff;
    padding-top: 20px;
    padding-bottom: 20px;
    position: absolute;
    top: 100%;
    transform: translateY(-200%);
    left: -15px;
    width: 100vw;
    transition: all 0.8s ease;
    border-bottom: 1px solid #c9c9c9;
    text-align: center;
    z-index: 90;
    overflow-y: auto;
  }
  .header--inner .header__menu > *:not(:last-child) {
    margin-right: 0px;
    margin-bottom: 10px;
  }
  .menu-on .header--inner .header__menu {
    transform: translateY(0);
    border-radius: 0;
  }
  .menu-on .header--inner .header__menu a {
    font-size: 16px;
  }
}

/*------------------------------------*
	@COMPONENTS #FOOTER
\*------------------------------------*/
.footer {
  box-shadow: -3px 1px 12px rgba(0, 0, 0, 0.07);
  padding-top: 40px;
}

.footer .container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media (max-width: 575px) {
  .footer {
    padding-top: 15px;
  }
}

.footer--inner {
  background-color: #fafafa;
  box-shadow: none;
  padding-top: 90px;
}

.footer--inner .container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.footer--inner .footer__logo {
  margin-bottom: 30px;
}

.footer--inner .footer__logo img {
  height: 60px;
}

@media (max-width: 768px) {
  .footer--inner .footer__logo img {
    height: 40px;
  }
}

@media (max-width: 575px) {
  .footer--inner .footer__logo {
    text-align: center;
  }
}

.footer--inner .footer__content {
  padding-bottom: 70px;
}

@media (max-width: 575px) {
  .footer--inner .footer__content {
    text-align: center;
    padding-bottom: 0;
  }
  .footer--inner .footer__content .row > * {
    text-align: center !important;
  }
}

.footer--inner .footer__text {
  color: #797979;
  font-size: 18px;
  font-weight: 500;
  text-transform: lowercase;
}

@media (max-width: 768px) {
  .footer--inner {
    padding-top: 40px;
  }
}

.footer__item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

@media (max-width: 575px) {
  .footer__item {
    align-items: center;
    margin-bottom: 30px;
  }
  .footer__item > * {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
}

.footer__menu {
  margin-bottom: 40px;
}

.footer__menu a {
  color: #797979;
  font-size: 14px;
  letter-spacing: 0.03px;
}

.footer__menu a:not(:last-child) {
  margin-right: 10px;
  position: relative;
}

.footer__menu a:not(:last-child)::after {
  content: '';
  width: 1px;
  height: 15px;
  background-color: #797979;
  position: absolute;
  top: 0;
  right: -5px;
}

.footer__menu a:hover {
  text-decoration: none;
  color: #c9c9c9;
}

@media (max-width: 575px) {
  .footer__menu {
    margin-bottom: 15px;
  }
}

/*------------------------------------*
	@COMPONENTS #SHOP
\*------------------------------------*/
.shop {
  padding-top: 40px;
  position: relative;
}

/*------------------------------------*
	@COMPONENTS #TITLE
\*------------------------------------*/
.title-wrap {
  margin-bottom: 40px;
  text-align: center;
}

@media (max-width: 768px) {
  .title-wrap {
    margin-bottom: 20px;
  }
}

.title-wrap--alt {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.title {
  font-size: 18px;
  font-weight: 500;
  color: #000;
  margin-bottom: 20px;
}

.title small {
  font-weight: 400;
  font-size: 18px;
}

@media (max-width: 768px) {
  .title small {
    font-size: 14px;
  }
}

@media (max-width: 560px) {
  .title--alt {
    text-align: center;
  }
}

.title--grey {
  color: #797979;
}

.title--uppercase {
  text-transform: uppercase;
}

.title--bottom {
  margin-bottom: 60px !important;
}

@media (max-width: 768px) {
  .title--bottom {
    margin-bottom: 15px !important;
  }
}

.title--xs {
  font-size: 16px;
  margin-bottom: 25px;
}

.title--lg {
  font-size: 36px;
  font-weight: 500;
}

@media (max-width: 768px) {
  .title--lg {
    font-size: 22px;
  }
}

.title--xl {
  font-size: 48px;
  font-weight: 700;
  line-height: 48px;
  text-align: left;
  text-transform: uppercase;
}

@media (max-width: 1200px) {
  .title--xl {
    font-size: 38px;
  }
}

@media (max-width: 768px) {
  .title--xl {
    font-size: 32px;
  }
}

@media (max-width: 560px) {
  .title--xl {
    font-size: 28px;
    line-height: 28px;
    margin-bottom: 10px;
  }
}

.title--green {
  color: #1acd8e;
  font-size: 36px;
  font-weight: 800;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  margin-bottom: 0;
}

.subtitle {
  color: #797979;
  font-size: 22px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  text-align: left;
}

@media (max-width: 768px) {
  .subtitle {
    font-size: 18px;
  }
}

.text {
  color: #000;
  font-size: 18px;
  font-weight: 500;
  text-align: left;
  margin-bottom: 20px;
}

.text strong {
  font-weight: 800;
}

@media (max-width: 768px) {
  .text {
    font-size: 16px;
  }
}

@media (max-width: 560px) {
  .text {
    font-size: 14px;
  }
}

/*------------------------------------*
	@COMPONENTS #SMALL
\*------------------------------------*/
.small {
  display: flex;
  align-items: center;
  font-size: 12px;
  margin-bottom: 5px;
}

.small dt {
  color: #797979;
  font-weight: 400;
}

.small dd {
  color: #1acd8e;
  font-weight: 500;
  padding-left: 2px;
}

/*------------------------------------*
	@COMPONENTS #SPLIT
\*------------------------------------*/
.split {
  margin-top: -100px;
  padding-top: 120px;
  padding-bottom: 70px;
  display: flex;
  position: relative;
}

.split::before {
  content: '';
  background-color: #f8f7f9;
  width: 50vw;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 50%;
  z-index: -1;
}

@media (max-width: 620px) {
  .split {
    flex-direction: column;
    padding-bottom: 0px;
  }
  .split .title {
    display: none;
  }
  .split::before {
    display: none;
  }
}

.split--alt {
  padding-bottom: 0;
  position: relative;
}

.split--alt::before {
  display: none;
}

@media (max-width: 768px) {
  .split--alt {
    flex-direction: column;
    align-items: center;
    padding-top: 100px;
  }
  .split--alt hr {
    margin-top: 15px;
    margin-bottom: 40px;
  }
}

.split--lg {
  padding-top: 150px;
  margin-top: 0;
}

.split--lg .split__content .wrap--center {
  display: none;
}

@media (max-width: 992px) {
  .split--lg .split__content .wrap--center {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

@media (max-width: 992px) {
  .split--lg {
    flex-direction: column;
    align-items: center;
    padding-top: 40px;
    padding-bottom: 0;
  }
  .split--lg::before {
    display: none;
  }
  .split--lg .wrap {
    align-items: flex-start;
  }
  .split--lg .wrap .title--green {
    font-size: 24px;
    margin-bottom: 20px;
    margin-right: 30px;
  }
  .split--lg .wrap .icon-star {
    width: 16px;
  }
  .split--lg .wrap--inline {
    align-items: center;
  }
}

@media (max-width: 620px) {
  .split--lg {
    padding-top: 20px;
  }
  .split--lg .title {
    display: inline-flex !important;
  }
  .split--lg .title--green {
    margin-bottom: 0 !important;
    font-size: 18px !important;
  }
  .split--lg .split__content {
    padding-bottom: 0 !important;
  }
  .split--lg .split__content img {
    width: 280px;
  }
  .split--lg .split__content .wrap--center {
    padding-top: 0;
    padding-bottom: 10px;
  }
}

.split__btn {
  width: 66px;
  height: 66px;
  border-radius: 100%;
  box-shadow: 1px 2px 6px rgba(213, 213, 213, 0.8);
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 70px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 99;
}

.split__btn .icon-cart {
  width: 30px !important;
  height: 22px !important;
  color: #959595;
}

.split__btn:hover {
  cursor: default;
}

@media (max-width: 560px) {
  .split__btn:hover {
    box-shadow: 1px 2px 6px rgba(213, 213, 213, 0.3);
    cursor: pointer;
  }
  .split__btn:hover .icon-cart {
    color: #1acd8e !important;
  }
}

@media (max-width: 620px) {
  .split__btn {
    top: 0;
    transform: translate(-50%, -50%);
  }
}

.split__content {
  padding-right: 100px;
  width: 50%;
}

.split__content:last-child {
  padding-left: 100px;
  padding-right: 0;
}

@media (max-width: 1200px) {
  .split__content {
    padding-right: 30px;
    padding-left: 30px;
  }
  .split__content:last-child {
    padding-right: 30px;
    padding-left: 30px;
  }
}

@media (max-width: 992px) {
  .split__content {
    width: 100%;
    max-width: 480px;
  }
  .split--lg .split__content {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (max-width: 560px) {
  .split__content {
    padding-right: 0px;
    padding-left: 0px;
    max-width: 100%;
  }
  .split__content:last-child {
    padding-right: 15px;
    padding-left: 15px;
  }
}

@media (max-width: 620px) {
  .split__content {
    padding-right: 0px;
    padding-bottom: 60px;
    width: 100%;
  }
  .split__content:last-child {
    position: relative;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 30px;
    background-color: #f8f7f9;
    padding-top: 40px;
    margin-right: -15px;
    margin-left: -15px;
    min-width: calc(100% + 30px);
  }
}

.split__sticky {
  position: sticky;
  top: 150px;
}

.split__sticky hr {
  margin-top: 30px;
  margin-bottom: 30px;
}

@media (max-width: 992px) {
  .split__sticky {
    padding-bottom: 30px;
  }
}

@media (max-width: 560px) {
  .split__sticky {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .split__sticky .list {
    text-align: left;
  }
  .split__sticky .title {
    text-align: center;
  }
  .split__sticky .subtitle {
    text-align: center;
  }
  .split__sticky .text {
    margin-bottom: 10px !important;
    text-align: center !important;
  }
  .split__sticky .wrap--inline {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .split__sticky hr {
    width: 100%;
    height: 1px;
  }
}

.split__content--alt {
  display: block;
  box-sizing: border-box;
}

@media (max-width: 992px) {
  .split__content--alt {
    position: relative;
    padding-top: 40px;
  }
  .split__content--alt::before {
    content: '';
    background-color: #f8f7f9;
    width: 100vw;
    height: 100%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 0;
    z-index: -1;
  }
}

@media (max-width: 768px) {
  .split__content--alt .text {
    margin-bottom: 0px;
  }
}

.split__img {
  width: 100%;
}

/*------------------------------------*
	@COMPONENTS #RECEIPT
\*------------------------------------*/
.receipt--alt {
  margin-bottom: 60px;
}

.receipt__list {
  color: #000;
  font-size: 12px;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 40px;
}

.receipt__list dt {
  color: #797979;
  font-weight: 400;
}

.receipt__list dd {
  color: #ff6d6c;
  font-weight: 500;
  margin-left: 5px;
}

hr + .receipt__list {
  margin-top: -35px;
}

.receipt__list + .form-group {
  margin-top: -20px;
}

@media (max-width: 768px) {
  hr + .receipt__list {
    margin-top: -25px;
  }
}

@media (max-width: 560px) {
  .receipt__list {
    margin-bottom: 30px;
  }
}

.receipt__list--alt {
  justify-content: flex-start;
  max-width: 268px;
}

.receipt__list--alt span {
  color: red;
}

/*------------------------------------*
	@COMPONENTS #FORMS
\*------------------------------------*/
.form-control {
  color: #000;
  border-color: transparent;
  background-color: #fff;
  border-radius: 10px;
  transition: all .3s ease 0s;
  height: 50px;
  /*PLACEHOLDER*/
}

.form-control:focus {
  box-shadow: none;
  border-color: #c9c9c9 !important;
}

.form-control::-webkit-input-placeholder {
  color: #797979;
  font-size: 16px;
  font-weight: 400;
}

.form-control::-moz-placeholder {
  color: #797979;
  opacity: 1;
  font-size: 16px;
  font-weight: 400;
}

.form-control:-ms-input-placeholder {
  color: #797979;
  font-size: 16px;
  font-weight: 400;
}

@media (max-width: 620px) {
  .form-control {
    height: 44px;
    padding-left: 20px;
    /*PLACEHOLDER*/
  }
  .form-control::-webkit-input-placeholder {
    font-size: 14px;
  }
  .form-control::-moz-placeholder {
    font-size: 14px;
  }
  .form-control:-ms-input-placeholder {
    font-size: 14px;
  }
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  font-size: 12px;
  margin-bottom: 5px;
  line-height: 1;
}

@media (max-width: 620px) {
  .form-group {
    margin-bottom: 10px;
  }
}

.form-group--alt {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 20px;
}

.form-group--alt label {
  margin-right: 10px;
  color: #797979;
  font-size: 14px;
  font-weight: 400;
}

.form-group--alt .form-control {
  max-width: 168px;
  border-radius: 10px;
}

@media (max-width: 992px) {
  .form-group--alt .form-control {
    max-width: 150px;
  }
}

@media (max-width: 620px) {
  .form-group--alt {
    margin-bottom: 25px;
  }
  .form-group--alt .form-control {
    height: 36px;
  }
}

select.form-control {
  color: #797979;
  opacity: 1;
  appearance: none;
  background-image: url(../images/icon-angle-down.svg);
  background-repeat: no-repeat;
  background-position: right 10px top 50%;
  background-size: 12px 8px;
  padding-left: 10px;
  padding-right: 30px;
}

@media (max-width: 620px) {
  select.form-control {
    padding-left: 20px;
    background-position: right 15px top 50%;
  }
}

/*------------------------------------*
	@COMPONENTS #DL-PRICE
\*------------------------------------*/
.dl-price {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  line-height: 1;
  margin-bottom: 20px;
}

.dl-price dt {
  font-size: 18px;
  font-weight: 500;
  color: #000;
}

.dl-price dd {
  color: #1acd8e;
  font-size: 32px;
  font-weight: 500;
}

.dl-price del {
  color: #000;
  font-size: 16px;
  font-weight: 300;
}

.dl-price + hr {
  margin-top: 20px;
}

@media (max-width: 620px) {
  .dl-price dd {
    font-size: 28px;
  }
}

.dl-price--border {
  margin-bottom: 0px;
  margin-right: 30px;
  padding-right: 30px;
  border-right: 1px solid #dbdbdb;
}

.dl-price--alt {
  color: #000;
  margin-bottom: 20px;
  margin-right: 0;
  padding-right: 0;
  border: none;
}

.dl-price--alt dt {
  font-size: 16px;
  font-weight: 400;
  color: #000;
}

.dl-price--alt dd {
  color: #000;
  font-weight: 400;
}

.dl-price--alt dd del {
  color: #959595;
  font-weight: 500;
}

@media (max-width: 768px) {
  .dl-price--alt + hr {
    margin-top: 15px;
  }
}

@media (max-width: 620px) {
  .dl-price--alt dt {
    font-size: 16px;
    font-weight: 400;
    color: #000;
  }
}

@media (max-width: 560px) {
  .dl-price--alt {
    margin-bottom: 15px;
  }
}

.dl-price--col {
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 0;
  margin-right: 30px;
  padding-right: 30px;
  border-right: 1px solid rgba(121, 121, 121, 0.15);
}

.dl-price--col dt {
  font-size: 12px;
  color: #797979;
  margin-bottom: 15px;
}

.dl-price--col dd {
  display: flex;
  align-items: flex-start;
}

.dl-price--col dd del {
  margin-right: 5px;
}

@media (max-width: 992px) {
  .dl-price--col {
    margin-right: 20px;
    padding-right: 20px;
  }
  .dl-price--col dd {
    flex-direction: column;
  }
}

.dl-price--shipping {
  margin-bottom: 0;
  flex-direction: column;
  align-items: stretch;
}

.dl-price--shipping dt {
  color: #000;
  font-size: 12px;
  margin-bottom: 5px;
}

.dl-price--shipping dd {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #797979;
  font-size: 12px;
  font-weight: 400;
}

.dl-price--shipping dd span {
  background-color: #ffc260;
  padding: 5px 15px;
  padding-left: 15px;
  padding-right: 15px;
  height: 30px;
  border-radius: 10px;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  margin-right: auto;
}

.dl-price--shipping dd del {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  margin-right: 5px;
}

.dl-price--shipping dd .icon {
  background-color: #1acd8e;
  color: #fff;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  padding: 6px;
  margin-right: 10px;
}

/*------------------------------------*
	@COMPONENTS #BUTTONS
\*------------------------------------*/
.btn-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
}

@media (max-width: 620px) {
  .btn-wrap {
    align-items: center;
  }
}

.btn {
  border-radius: 35px;
  background-color: #1acd8e;
  padding: 15px 60px;
  color: #fff;
  font-size: 22px;
  font-weight: 800;
  text-transform: uppercase;
  transition: all .3s ease 0s;
  margin-bottom: 5px;
  box-shadow: none !important;
  line-height: 1;
}

.btn:hover {
  opacity: 0.5;
  color: #fff;
}

@media (max-width: 992px) {
  .btn {
    padding: 15px 40px;
  }
}

.btn--short {
  padding: 15px 44px;
}

@media (max-width: 560px) {
  .btn--short {
    font-size: 18px;
  }
}

.btn--xs {
  font-size: 14px;
  font-weight: 800;
  text-transform: capitalize;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 34px;
  padding: 0 15px;
}

.btn--sm {
  padding-top: 15px;
  padding-bottom: 15px;
  font-size: 18px;
  margin-bottom: 10px;
}

@media (max-width: 768px) {
  .btn--sm {
    padding: 10px 25px;
    font-size: 16px;
  }
}

.btn--lg {
  padding-left: 100px;
  padding-right: 100px;
  font-size: 22px;
}

@media (max-width: 560px) {
  .btn--lg {
    padding-left: 60px;
    padding-right: 60px;
    font-size: 18px;
  }
}

.btn--clean {
  display: flex;
  padding: 0;
  border-radius: none;
  background-color: transparent;
}

.btn--clean:hover {
  opacity: 0.8;
}

.btn--lang {
  z-index: 200;
}

@media (max-width: 768px) {
  .btn--lang {
    position: absolute;
    left: 0px;
  }
  .btn--lang img {
    width: 24px;
  }
}

.btn--shake {
  animation: shake-animation 4s ease infinite;
  transform-origin: 50% 50%;
}

@keyframes shake-animation {
  0% {
    transform: translate(0, 0);
  }
  1.78571% {
    transform: translate(10px, 0);
  }
  3.57143% {
    transform: translate(0, 0);
  }
  5.35714% {
    transform: translate(10px, 0);
  }
  7.14286% {
    transform: translate(0, 0);
  }
  8.92857% {
    transform: translate(10px, 0);
  }
  10.71429% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}

/*------------------------------------*
	@COMPONENTS #INFO
\*------------------------------------*/
.info-wrap {
  box-shadow: -3px 1px 12px rgba(0, 0, 0, 0.07);
  padding-top: 40px;
  padding-bottom: 40px;
  width: 100vw;
}

@media (max-width: 620px) {
  .info-wrap {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .info-wrap hr {
    margin-top: 15px;
    margin-bottom: 15px;
  }
}

@media (max-width: 560px) {
  .info-wrap--sm {
    display: none;
  }
}

.info-wrap--xs {
  display: none;
}

@media (max-width: 560px) {
  .info-wrap--xs {
    display: block;
    padding-top: 15px;
    padding-bottom: 15px;
  }
}

.info {
  display: flex;
  justify-content: center;
}

.info .info__content {
  display: flex;
}

.info .info__content .icon {
  margin-right: 15px;
  width: 30px;
  height: 30px;
}

.info .info__content p {
  color: #797979;
  font-size: 14px;
  font-weight: 400;
}

.info .info__content p strong {
  display: block;
  font-weight: 400;
  color: #000;
}

.info .info__content:not(:last-child) {
  margin-right: 60px;
}

@media (max-width: 620px) {
  .info .info__content {
    flex-direction: column;
    align-items: center;
  }
  .info .info__content:not(:last-child) {
    margin-right: 20px;
  }
  .info .info__content .icon {
    width: 25px;
    height: 25px;
    margin-right: 0;
    margin-bottom: 10px;
  }
  .info .info__content p {
    font-size: 12px;
  }
  .info .info__content p span {
    display: none;
  }
}

.info__box {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin: -8px;
}

.info__box > * {
  margin: 8px !important;
}

.info__box--alt {
  flex-wrap: nowrap;
}

.info__box--alt > * {
  flex-shrink: 1;
  min-width: 0;
}

@media (max-width: 768px) {
  .info__box--alt {
    margin: -4px;
  }
  .info__box--alt > * {
    margin: 4px !important;
  }
}

@media (max-width: 560px) {
  .info__box--alt img {
    max-height: 12px;
  }
}

/*------------------------------------*
	@COMPONENTS #CAROUSEL
\*------------------------------------*/
.carousel {
  position: relative;
}

.slick-arrow {
  position: absolute;
  top: -45px;
  z-index: 1;
}

.slick-arrow .icon {
  width: 10px;
  height: 18px;
  color: #c9c9c9;
}

.slick-arrow:hover .icon {
  color: #1acd8e;
}

@media (max-width: 560px) {
  .slick-arrow {
    top: -20px;
  }
}

.slick-arrow--prev {
  right: 25px;
}

.slick-arrow--next {
  right: 0px;
}

/*------------------------------------*
	@COMPONENTS #TESTIMONIALS
\*------------------------------------*/
.testimonials {
  padding-top: 110px;
  padding-bottom: 110px;
}

@media (max-width: 768px) {
  .testimonials {
    padding-top: 70px;
    padding-bottom: 30px;
  }
}

@media (max-width: 768px) {
  .testimonials--bottom {
    margin-bottom: 40px;
  }
  .testimonials--bottom .testimonials__img {
    padding-top: 30px;
  }
}

.testimonials__img {
  padding-top: 100px;
  margin-bottom: 30px;
}

.testimonials__img img {
  width: 100%;
  height: 370px;
}

@media (max-width: 768px) {
  .testimonials__img {
    padding-top: 60px;
    margin-bottom: 20px;
  }
  .testimonials__img img {
    height: 300px;
  }
}

@media (max-width: 560px) {
  .testimonials__img img {
    height: 100%;
    min-height: 150px;
  }
}

/*------------------------------------*
	@COMPONENTS #AVATAR
\*------------------------------------*/
.avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  background-color: #fff;
}

.avatar img {
  width: 132px;
  height: 132px;
  border-radius: 50%;
  margin-bottom: 0;
}

.testimonials .avatar {
  margin-right: 34px;
}

@media (max-width: 768px) {
  .avatar img {
    width: 76px;
    height: 76px;
  }
  .testimonials .avatar {
    margin-right: 12px;
  }
}

/*------------------------------------*
	@COMPONENTS #SOCIAL
\*------------------------------------*/
.social-menu {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
}

.social {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 46px;
  height: 46px;
  border-radius: 999px;
  background-color: #c9c9c9;
}

.social .icon {
  color: #fff;
}

.social:not(:last-child) {
  margin-right: 20px;
}

.social:hover {
  opacity: 0.5;
}

@media (max-width: 768px) {
  .social {
    width: 32px;
    height: 32px;
  }
}

span.social:hover {
  opacity: 1;
}

.social-menu--start {
  justify-content: start;
}

@media (max-width: 575px) {
  .social-menu--start {
    justify-content: center;
  }
}

/*------------------------------------*
	@COMPONENTS #ICONS
\*------------------------------------*/
.icon-social-message {
  width: 24px;
  height: 19px;
}

@media (max-width: 768px) {
  .icon-social-message {
    width: 18px;
    height: 14px;
  }
}

.icon-social-instagram {
  width: 20px;
  height: 20px;
}

@media (max-width: 768px) {
  .icon-social-instagram {
    width: 15px;
    height: 14px;
  }
}

.icon-social-facebook {
  width: 10px;
  height: 20px;
}

@media (max-width: 768px) {
  .icon-social-facebook {
    width: 7px;
    height: 14px;
  }
}

.icon-phone {
  width: 18px;
  height: 19px;
}

@media (max-width: 768px) {
  .icon-phone {
    width: 12px;
    height: 14px;
  }
}

.icon-mail {
  width: 24px;
  height: 19px;
}

@media (max-width: 768px) {
  .icon-mail {
    width: 18px;
    height: 14px;
  }
}

.icon-star {
  color: #ffc260;
}

/*------------------------------------*
	@COMPONENTS #FAQ
\*------------------------------------*/
.faq {
  margin-bottom: 110px;
}

.faq .brick {
  margin-bottom: 50px;
}

@media (max-width: 768px) {
  .faq {
    margin-bottom: 30px;
  }
  .faq .brick {
    margin-bottom: 25px;
  }
}

/*------------------------------------*
	@COMPONENTS #BRICK
\*------------------------------------*/
.brick {
  display: flex !important;
  margin-right: 70px;
  outline: none;
}

@media (max-width: 992px) {
  .brick {
    margin-right: 0;
  }
}

.brick__title {
  color: #000;
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 10px;
}

@media (max-width: 992px) {
  .brick__title {
    font-size: 14px;
  }
}

.brick__text {
  color: #797979;
  line-height: 1.5;
}

@media (max-width: 768px) {
  .brick__text {
    font-size: 14px;
  }
}

/*------------------------------------*
	@COMPONENTS #CARD
\*------------------------------------*/
.card-wrap {
  display: flex;
  padding-top: 40px;
}

.card-wrap--alt {
  margin-left: -15px;
  margin-right: -15px;
  margin-bottom: 40px;
  padding-top: 0;
}

.card-wrap--alt > * {
  padding-left: 15px;
  padding-right: 15px;
}

@media (max-width: 768px) {
  .card-wrap--alt {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 0px;
  }
  .card-wrap--alt > * {
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 30px;
  }
}

.card {
  padding: 40px 70px 80px 70px;
  max-width: 570px;
  width: 100%;
  background-color: #f8f7f9;
  border: none;
}

.card .title:not(:first-child) {
  margin-top: 70px;
}

@media (max-width: 992px) {
  .card {
    padding: 25px 30px 30px 30px;
  }
}

@media (max-width: 768px) {
  .card {
    order: 2;
  }
}

@media (max-width: 560px) {
  .card {
    margin-right: -15px;
    margin-left: -15px;
    padding-left: 20px;
    padding-right: 20px;
    min-width: calc(100% + 30px);
  }
  .card .form .title {
    display: block;
    margin-top: 0px;
  }
  .card .form .title--alt {
    margin-top: 40px;
  }
}

.card--nobackground {
  background-color: transparent;
  padding-top: 0px;
  padding-right: 0;
}

@media (max-width: 768px) {
  .card--nobackground {
    order: 1;
    padding-left: 0;
    padding-right: 0;
  }
}

@media (max-width: 560px) {
  .card--nobackground {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.card-over {
  display: flex;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  border: 1px solid #c9c9c9;
  background-color: #fff;
  max-width: 380px;
  width: 100%;
  position: fixed;
  bottom: 30px;
  transform: translateX(-200%);
  left: 0;
  z-index: 1200;
  transition: all 1.2s ease;
}

.card-over.card-over-on {
  transform: translateX(0);
}

.card-over .card__cover img {
  border-radius: 10px 0 0 10px;
  height: 118px;
}

.card-over .card__content {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: start;
}

.card-over .card__title {
  color: #000;
  font-size: 16px;
  font-weight: 500;
  line-height: 1;
  text-align: left;
  margin-bottom: 10px;
}

.card-over .card__text {
  color: #797979;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  text-align: left;
  margin-bottom: 0px;
}

.card-over .card__text--green {
  color: #1acd8e;
  margin-top: auto;
}

@media (max-width: 768px) {
  .card-over {
    max-width: 300px;
    width: 100%;
  }
  .card-over .card__cover img {
    border-radius: 10px 0 0 10px;
    height: 80px;
  }
  .card-over .card__content {
    padding: 8px;
  }
  .card-over .card__title {
    margin-bottom: 6px;
  }
  .card-over.card-over-on {
    left: 10px;
  }
}

.card--alt {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: transparent;
  padding: 0 15px;
}

.card--alt .card__content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 30px;
}

.card--alt img {
  width: 100%;
  border-radius: 5px;
}

.card--alt .card__title {
  color: #000;
  font-size: 18px;
  font-weight: 800;
  text-align: left;
  margin-bottom: 10px;
}

.card--alt .card__text {
  color: #797979;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  margin-bottom: 0;
}

@media (max-width: 768px) {
  .card--alt {
    max-width: 420px;
    flex-direction: column-reverse;
    margin-bottom: 40px;
  }
  .card--alt .card__content {
    margin-bottom: 0px;
  }
  .card--alt img {
    margin-bottom: 20px;
  }
}

@media (max-width: 560px) {
  .card--alt {
    align-items: center;
    min-width: 280px !important;
  }
  .card--alt .card__content {
    align-items: center;
  }
  .card--alt .card__title,
  .card--alt .card__text {
    text-align: center;
  }
}

/*------------------------------------*
	@COMPONENTS #PAYMENTS
\*------------------------------------*/
.payments-wrap {
  display: flex;
}

@media (max-width: 560px) {
  .payments-wrap {
    justify-content: center;
  }
}

.payments {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
}

.payments:not(:last-child) {
  margin-right: 30px;
}

.payments img {
  margin-bottom: 6px !important;
}

/*------------------------------------*
	@COMPONENTS #BOX
\*------------------------------------*/
.box-wrap {
  margin-bottom: 40px;
}

@media (max-width: 768px) {
  .box-wrap > * {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

@media (max-width: 768px) {
  .box-wrap {
    margin-bottom: 10px;
  }
}

.box {
  box-shadow: 1px 2px 6px rgba(213, 213, 213, 0.8);
  border-radius: 10px;
  background-color: #ffffff;
  width: 100%;
  max-width: 470px;
  padding: 50px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
  position: relative;
}

@media (max-width: 768px) {
  .box {
    padding: 30px 30px;
    margin-bottom: 10px;
  }
}

.box__img {
  margin-bottom: 40px;
}

.box__btn {
  margin-bottom: 10px;
}

/*------------------------------------*
	@COMPONENTS #QUANTITY
\*------------------------------------*/
.qty {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  max-width: 40px;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}

.qty .form-control {
  height: 40px;
  border-radius: 3px;
  border: 1px solid #dbdbdb;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 30px;
  padding: 5px;
}

@media (max-width: 768px) {
  .qty .form-control {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

.qty .btn {
  width: 24px;
  height: 24px;
  padding: 0 !important;
  margin-bottom: 0px;
  background-color: #c9c9c9;
  border-radius: 999px;
  text-transform: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.qty .btn .icon {
  width: 10px;
  height: 10px;
}

.qty .btn:hover {
  background-color: #1acd8e;
}

.qty .qty__down {
  font-size: 1.5rem;
  margin-bottom: 0;
}

/*------------------------------------*\
	@COMPONENTS #MODAL
\*------------------------------------*/
.modal {
  background-color: transparent;
  transition: all .8s ease;
}

@media (max-width: 768px) {
  .modal {
    padding-left: 10px;
    padding-right: 10px !important;
  }
}

.modal-backdrop {
  box-shadow: 1px 2px 14px #959595;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.25);
}

.modal-dialog {
  max-width: 520px;
  margin-top: 260px;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 768px) {
  .modal-dialog {
    margin-top: 240px;
  }
}

.modal-content {
  background-color: #fff;
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 1px 2px 14px #959595;
  border-radius: 10px;
  background-color: #ffffff;
}

.modal-content hr {
  min-width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
}

.modal-content img {
  margin-bottom: 40px;
}

@media (max-width: 768px) {
  .modal-content {
    padding: 30px 20px;
  }
  .modal-content hr {
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .modal-content img {
    margin-bottom: 30px;
  }
}

.modal__title {
  color: #000;
  font-size: 32px;
  font-weight: 500;
  margin-bottom: 30px;
}

@media (max-width: 768px) {
  .modal__title {
    font-size: 24px;
    margin-bottom: 25px;
  }
}

.modal__text {
  color: #797979;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 0px;
  line-height: 1.55;
  max-width: 370px;
  text-align: center;
}

.modal__text span {
  color: #ff6d6c;
  font-weight: 500;
  text-transform: uppercase;
}

.modal__text strong {
  color: #000;
  font-weight: 500;
  text-transform: uppercase;
}

@media (max-width: 768px) {
  .modal__text {
    font-size: 14px;
  }
}

.modal-content .checkbox {
  margin-bottom: 30px !important;
}

@media (max-width: 768px) {
  .modal-content .checkbox {
    margin-bottom: 25px !important;
  }
}

.modal-content .checkbox__label {
  max-width: 325px;
  text-align: left;
  margin-bottom: 0 !important;
}

@media (max-width: 768px) {
  .modal-content .checkbox__label {
    font-size: 10px;
    padding-left: 40px;
  }
  .modal-content .checkbox__label::before {
    left: 10px;
  }
  .modal-content .checkbox__label::after {
    left: 13px;
  }
}

/*------------------------------------*
	@COMPONENTS #COLLAPSE
\*------------------------------------*/
.collapse-wrap .dl-price--col {
  display: none;
}

.collapse-wrap .split__btn {
  position: absolute;
  top: 0px;
  left: 80px;
  box-shadow: 1px 2px 6px rgba(213, 213, 213, 0.8) !important;
  background-color: #ffffff;
  cursor: default;
}

.collapse-wrap .split__btn .icon-cart {
  width: 30px !important;
  height: 22px !important;
  color: #959595 !important;
}

@media (max-width: 768px) {
  .collapse-wrap {
    display: flex;
    align-items: center;
    padding-top: 15px;
    border-top: 1px solid #dbdbdb;
  }
  .collapse-wrap .dl-price--col {
    display: block;
    margin-right: 0;
    padding-right: 0;
    border-right: none;
    margin-left: auto;
  }
  .collapse-wrap .split__btn {
    position: static;
    transform: none;
    margin-right: 15px;
  }
}

@media (max-width: 560px) {
  .collapse-wrap .split__btn {
    top: 45px;
  }
}

@media (min-width: 768px) {
  .collapse-wrap .collapse {
    display: block !important;
  }
}

.collapse-wrap .collapsed .icon-angle-up {
  transform: rotate(180deg);
}

.collapse-wrap .collapse-trigger {
  padding: 0;
  border-radius: 0;
  color: #797979;
  font-size: 14px;
  text-decoration: none !important;
  position: relative;
  display: flex;
  align-items: center;
}

.collapse-wrap .collapse-trigger span {
  margin-right: 10px;
}

.collapse-wrap .collapse-trigger .icon-angle-up {
  color: #797979;
  width: 10px;
  height: 6px;
  transform: rotate(0);
}

.collapse-wrap .collapse-trigger:hover {
  color: #b0b0b0;
}

@media (min-width: 768px) {
  .collapse-wrap .collapse-trigger {
    display: none;
  }
}

/*------------------------------------*
	@COMPONENTS #WRAP
\*------------------------------------*/
.wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 14px;
}

.wrap--center {
  justify-content: center;
}

.wrap--start {
  justify-content: start;
  align-items: center;
}

.wrap--start .social {
  margin-right: 10px;
}

@media (max-width: 575px) {
  .wrap--start {
    align-items: center;
    justify-content: center;
  }
}

.wrap--alt {
  flex-direction: column;
}

.wrap--inline {
  display: inline-flex;
  flex-direction: column;
}

/*------------------------------------*
	@COMPONENTS #LAYOUT
\*------------------------------------*/
.list {
  padding-left: 40px;
}

.list li {
  margin-bottom: 30px;
  position: relative;
  list-style: none;
}

.list li::before {
  content: '';
  background-image: url(../images/icon-check-orange.svg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 17px;
  height: 12px;
  position: absolute;
  top: 5px;
  left: -40px;
}

.list--bottom {
  margin-bottom: 120px;
}

.list--bottom li {
  color: #000;
  font-size: 18px;
  margin-bottom: 20px;
}

@media (max-width: 992px) {
  .list--bottom {
    margin-bottom: 60px;
  }
}

@media (max-width: 560px) {
  .list--bottom {
    margin-bottom: 40px;
  }
  .list--bottom li {
    font-size: 16px;
  }
}

/*------------------------------------*
	@COMPONENTS #LINK
\*------------------------------------*/
.link {
  color: #797979 !important;
  font-size: 14px;
  font-weight: 400;
  text-decoration: none !important;
  display: flex;
  align-items: center;
}

.link:hover {
  opacity: 0.5;
}

.link small {
  font-size: 12px;
}

.link .icon {
  width: 6px;
  height: 10px;
  color: #797979;
  margin-left: 10px;
}

@media (max-width: 768px) {
  .link {
    font-size: 12px;
  }
}

.link--green {
  color: #1acd8e !important;
  font-size: 12px;
  font-weight: 400;
}

@media (max-width: 620px) {
  .link--green {
    font-size: 10px;
  }
}

.link--grey {
  color: #797979;
  font-size: 24px;
  font-weight: 400;
  line-height: 25px;
  text-align: left;
}

@media (max-width: 768px) {
  .link--grey {
    font-size: 18px;
  }
}

/*------------------------------------*
	@COMPONENTS #PRODUCT
\*------------------------------------*/
.product {
  display: flex;
  padding-bottom: 100px;
  margin-bottom: 100px;
  border-bottom: 1px solid #dbdbdb;
}

.product:last-child {
  padding-bottom: 40px;
  margin-bottom: 0;
  border-bottom: none;
}

@media (max-width: 992px) {
  .product {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 60px;
    margin-bottom: 60px;
  }
}

@media (max-width: 768px) {
  .product {
    padding-bottom: 40px;
    margin-bottom: 40px;
  }
}

.product-images {
  display: flex;
  justify-content: start;
  flex-direction: column;
  align-items: start;
  margin-bottom: 20px;
}

.product-images img {
  margin-bottom: 40px;
  max-width: 100%;
}

@media (max-width: 992px) {
  .product-images {
    display: none;
  }
}

.product--reverse .product__content {
  margin-right: 0;
  margin-left: 30px;
}

@media (max-width: 992px) {
  .product--reverse {
    flex-direction: column-reverse;
  }
  .product--reverse .product__content {
    margin-left: 0;
  }
}

.carousel-wrap .product__large {
  width: 100%;
  margin: 0 auto;
  position: relative;
  z-index: 10;
}

.carousel-wrap .product__large img {
  margin-bottom: 20px;
  box-sizing: border-box;
  border-radius: 5px;
}

.carousel-wrap .product__thumbs {
  display: none;
}

.carousel-wrap .product__thumbs img {
  text-align: center;
  box-sizing: border-box;
  cursor: pointer;
  margin-left: 10px;
  margin-right: 10px;
  border: 1px solid #c9c9c9;
  border-radius: 5px;
}

.carousel-wrap .product__thumbs .slick-slide {
  opacity: .5;
  transition: all .3s ease 0s;
}

.carousel-wrap .product__thumbs .slick-slide.slick-current {
  opacity: 1;
}

.carousel-wrap .product__thumbs .slick-dots {
  display: flex;
  padding-left: 0;
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
  list-style: none;
}

.carousel-wrap .product__thumbs .slick-dots button {
  background-color: #c9c9c9;
  width: 10px;
  height: 10px;
  padding: 0;
  border-radius: 999px;
}

.carousel-wrap .product__thumbs .slick-dots li {
  position: relative;
  color: transparent;
  background-color: transparent;
  font-size: 0;
}

.carousel-wrap .product__thumbs .slick-dots li:not(:last-child) {
  margin-right: 20px;
}

.carousel-wrap .product__thumbs .slick-dots .slick-active button {
  background-color: #797979;
}

@media (max-width: 992px) {
  .carousel-wrap .product__thumbs {
    display: block;
  }
}

@media (max-width: 560px) {
  .carousel-wrap .product__thumbs .slick-dots {
    bottom: 10px;
  }
  .carousel-wrap .product__thumbs .slick-dots li {
    bottom: -15px;
  }
  .carousel-wrap .product__thumbs .slick-dots li:not(:last-child) {
    margin-right: 10px;
  }
}

.product__cover img {
  border-radius: 5px;
}

@media (max-width: 768px) {
  .product__cover img {
    max-height: 280px;
    height: 100%;
  }
}

@media (max-width: 560px) {
  .product__cover img {
    max-height: 240px;
  }
}

@media (max-width: 350px) {
  .product__cover img {
    max-height: 200px;
  }
}

.product__content {
  margin-right: 30px;
}

@media (max-width: 768px) {
  .product__content {
    margin-right: 0;
  }
}

.product__title {
  color: #000;
  font-size: 48px;
  font-weight: 700;
  line-height: 1;
  text-align: left;
  margin-bottom: 20px;
}

@media (max-width: 992px) {
  .product__title {
    font-size: 36px;
  }
}

@media (max-width: 768px) {
  .product__title {
    font-size: 28px;
  }
}

@media (max-width: 560px) {
  .product__title {
    text-align: center;
    font-size: 26px;
  }
}

.product__text {
  color: #797979;
  font-size: 22px;
  font-weight: 400;
  line-height: normal;
}

@media (max-width: 992px) {
  .product__text {
    font-size: 18px;
    margin-bottom: 20px !important;
  }
}

@media (max-width: 560px) {
  .product__text {
    font-size: 16px;
    text-align: center;
  }
}

/*------------------------------------*
	@COMPONENTS #CARD
\*------------------------------------*/
.section {
  padding-top: 100px;
  padding-bottom: 100px;
}

@media (max-width: 992px) {
  .section {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

@media (max-width: 768px) {
  .section {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

.section--bordered {
  box-shadow: -3px 1px 12px rgba(0, 0, 0, 0.07);
  box-shadow: -3px 1px 12px rgba(0, 0, 0, 0.07);
}

/*------------------------------------*
	@COMPONENTS #BLOCK
\*------------------------------------*/
.block-wrap {
  max-width: 770px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.block-wrap .block {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 10px;
}

.block-wrap .block__btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 15px 20px;
  border-radius: 5px;
  background-color: #fafafa;
  font-size: 22px;
  font-weight: 500;
  color: #000;
  border-bottom: 1px solid #eeeeee !important;
}

.block-wrap .block__btn .icon-angle-up {
  color: #797979;
  width: 10px;
  height: 6px;
  transform: rotate(0);
}

@media (max-width: 560px) {
  .block-wrap .block__btn {
    font-size: 18px;
  }
}

.block-wrap .collapsed {
  background-color: #f0f0f0;
  border-bottom: none;
}

.block-wrap .collapsed .icon-angle-up {
  transform: rotate(180deg);
}

.block-wrap .block__body {
  padding: 25px 20px;
  background-color: #fafafa;
  position: relative;
  border-radius: 0 0 5px 5px !important;
}

.block-wrap .block__body:before {
  content: '';
  background-color: #1acd8e;
  width: 3px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0px;
  border-radius: 0 0 0 5px !important;
}

.block-wrap .block__text {
  color: #797979;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: 25px;
  text-align: left;
}

@media (max-width: 560px) {
  .block-wrap .block__text {
    font-size: 14px;
  }
}

/*------------------------------------*
	@COMPONENTS #COPYRIGHT
\*------------------------------------*/
.copyright {
  padding-top: 10px;
  padding-bottom: 10px;
  width: 100%;
  border-top: 1px solid #dbdbdb;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 560px) {
  .copyright {
    font-size: 10px;
    letter-spacing: 0.02px;
  }
}

.copyright--space {
  padding-top: 30px;
  padding-bottom: 30px;
  justify-content: space-between;
}

@media (max-width: 620px) {
  .copyright--space {
    flex-direction: column;
    justify-content: center;
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .copyright--space p {
    font-size: 12px;
  }
}

.copyright__list {
  display: flex;
  padding-left: 0;
  list-style: none;
  margin-bottom: 0;
}

.copyright__list li {
  position: relative;
}

.copyright__list li:not(:last-child) {
  margin-right: 14px;
}

.copyright__list li:not(:last-child)::before {
  content: '';
  background-color: #c9c9c9;
  width: 1px;
  height: 10px;
  position: absolute;
  top: 5px;
  right: -7px;
}

.copyright__list li a {
  font-size: 14px;
  color: #797979;
  text-decoration: none;
  line-height: 1;
}

.copyright__list li a:hover {
  opacity: 0.5;
}

@media (max-width: 560px) {
  .copyright__list {
    margin-bottom: 10px;
  }
  .copyright__list li a {
    font-size: 12px;
  }
}

/*------------------------------------*
	@COMPONENTS #FLY
\*------------------------------------*/
.fly {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px 15px 10px;
  background-color: #fff;
  box-shadow: -3px 1px 12px rgba(0, 0, 0, 0.2);
  transform: translateY(200%);
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  z-index: 1111;
  transition: all .5s ease;
}

.fly .btn {
  width: 100%;
  max-width: 320px;
}

.fly .btn:not(:last-child) {
  margin-bottom: 5px;
}

.fly span {
  color: #1acd8e;
  font-size: 12px;
}

.scrolled .fly {
  transform: translateY(0);
}

@media (min-width: 561px) {
  .fly {
    display: none;
  }
}

/*------------------------------------*
	@TRUMPS #COLORS
\*------------------------------------*/
.color-primary {
  color: #797979 !important;
}

.color-alt {
  color: #000 !important;
}

/*------------------------------------*
	@TRUMPS #HELPERS
\*------------------------------------*/
.clean {
  margin: 0;
  padding: 0;
}

.no-transition,
.no-transition * {
  transition: none !important;
}

.amp {
  font-family: Baskerville, Palatino, "Palatino Linotype", "Book Antiqua", serif;
  font-size: 1.4em;
  font-style: italic;
  font-weight: normal;
}

.nowrap {
  white-space: nowrap;
}

/*------------------------------------*
	@TRUMPS #LAYOUT
\*------------------------------------*/
.spaced1x {
  margin-bottom: 10px !important;
}

.spaced2x {
  margin-bottom: 20px !important;
}

.spaced3x {
  margin-bottom: 30px !important;
}

.spaced4x {
  margin-bottom: 40px !important;
}

.spaced5x {
  margin-bottom: 50px !important;
}

.spaced6x {
  margin-bottom: 60px !important;
}

.spaced7x {
  margin-bottom: 70px !important;
}

.spaced8x {
  margin-bottom: 80px !important;
}

.spaced9x {
  margin-bottom: 90px !important;
}

.spaced10x {
  margin-bottom: 100px !important;
}

/*------------------------------------*
	@TRUMPS #PRINT
\*------------------------------------*/
/**
 * Very crude, reset-like styles taken from the HTML5 Boilerplate:
 * https://github.com/h5bp/html5-boilerplate/blob/3c3c5e64604209a4d63e1e4c48dd245d45fadfd9/css/main.css#L200-L234
 */
@media print {
  *,
  *:before,
  *:after {
    background: transparent !important;
    color: #000 !important;
    /* Black prints faster: h5bp.com/s */
    box-shadow: none !important;
    text-shadow: none !important;
  }
  a,
  a:visited {
    text-decoration: underline;
  }
  a[href]:after {
    content: " (" attr(href) ")";
  }
  abbr[title]:after {
    content: " (" attr(title) ")";
  }
  /**
     * Don’t show links that are fragment identifiers, or use the `javascript:`
     * pseudo protocol.
     */
  a[href^="#"]:after,
  a[href^="javascript:"]:after {
    content: "";
  }
}
