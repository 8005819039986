/*------------------------------------*
	@COMPONENTS #BUTTONS
\*------------------------------------*/

.btn-wrap {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 0;
	
	@media (max-width: 620px) {
		align-items: center;
	}
}

.btn {
	border-radius: 35px;
	background-color: #1acd8e;
	padding: 15px 60px;
	color: $white;
	font-size: 22px;
	font-weight: 800;
	text-transform: uppercase;
	@include trans;
	margin-bottom: 5px;
	box-shadow: none !important;
	line-height: 1;

	&:hover {
		opacity: 0.5;
		color: $white;
	}
	
	@media (max-width: $screen-md) {
		padding: 15px 40px;
	}
}

.btn--short {
	padding: 15px 44px;

	@media (max-width: $screen-xs) {	
		font-size: 18px;
	}
}

.btn--xs {
	font-size: 14px;
	font-weight: 800;
	text-transform: capitalize;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 34px;
	padding: 0 15px;
}

.btn--sm {
	padding-top: 15px;
	padding-bottom: 15px;
	font-size: 18px;
	margin-bottom: 10px;

	@media (max-width: $screen-sm) {
		padding: 10px 25px;
		font-size: 16px;
	}
}

.btn--lg {
	padding-left: 100px;
	padding-right: 100px;
	font-size: 22px;

	@media (max-width: $screen-xs) {	
		padding-left: 60px;
		padding-right: 60px;
		font-size: 18px;
	}
}

.btn--clean {
	display: flex;
	padding: 0;
	border-radius: none;
	background-color:transparent;

	&:hover {
		opacity: 0.8;
	}
}

.btn--lang {
	z-index: 200;
	@media (max-width: $screen-sm) {	
		position: absolute;
		left: 0px;
		
		img {
			width: 24px;
		}
	}
}

.btn--shake {
	animation: shake-animation 4s ease infinite;
	transform-origin: 50% 50%;
}

@keyframes shake-animation {
	0% { transform:translate(0,0) }
	1.78571% { transform:translate(10px,0) }
	3.57143% { transform:translate(0,0) }
	5.35714% { transform:translate(10px,0) }
	7.14286% { transform:translate(0,0) }
	8.92857% { transform:translate(10px,0) }
	10.71429% { transform:translate(0,0) }
	100% { transform:translate(0,0) }
}