/*------------------------------------*
	@COMPONENTS #BLOCK
\*------------------------------------*/

.block-wrap {
    max-width: 770px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    .block {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-bottom: 10px;
    }
    
    .block__btn {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 15px 20px;
        border-radius: 5px;
        background-color: #fafafa;        
        font-size: 22px;
        font-weight: 500;
        color: $black;
        border-bottom: 1px solid #eeeeee !important;
        
        .icon-angle-up {
            color: $grey-dark;
            width: 10px;
            height: 6px;
            transform: rotate(0);
        }

        @media (max-width: $screen-xs) {	
            font-size: 18px;
        }
    }
    
    .collapsed {
        background-color: #f0f0f0;
        border-bottom: none;

        .icon-angle-up {
            transform: rotate(180deg);
        }
    }

    .block__body {
        padding: 25px 20px;
        background-color: #fafafa;
        position: relative;
        border-radius: 0 0 5px 5px !important; 

        &:before {
            content: '';
            background-color: $green;
            width: 3px;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0px;
            border-radius: 0 0 0 5px !important; 
        }
    }

    .block__text {
        color: #797979;
        font-size: 16px;
        font-weight: 400;
        font-style: normal;
        letter-spacing: normal;
        line-height: 25px;
        text-align: left;

        @media (max-width: $screen-xs) {	
            font-size: 14px;
        }
    }
}